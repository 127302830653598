import { injectable, inject } from "inversify";
import {IErrorHandlerService} from "../ErrorHandlerService";
import {IApiService} from "../ApiService";
import * as ApiUrlConstants from "../../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { ISharedService } from "../SharedService";
import { DELEGATES } from "../../entry";
import { IJqueryService } from "../JQueryService";
import { IButtonAggregatorModel, IButtonModel } from "../../Models/ButtonAggregatorModel";
import container from "../../Wires/Bootstrapper";

export type ButtonAggregatorProxy = { [id: string]: IButtonModel; }

export interface IButtonAggregatorService {    
    refresh(navId: any): void;
    setButtonAggregator(model: IButtonAggregatorModel): void;    
}

@injectable()
export class ButtonAggregatorService implements IButtonAggregatorService {
    protected _apiService: IApiService;
    protected _handlerService: IErrorHandlerService;
    protected _sharedService: ISharedService;
    protected _jqueryService: IJqueryService;
    _buttonAggregatorModel: IButtonAggregatorModel = null;
    _proxy: ButtonAggregatorProxy;
    
    //AA chiamato on demand forza il reload di un button aggregator
    refresh(navId:any): void {
        let model: any = { params: { navigationId: navId}};

        this._apiService.get<any>(ApiUrlConstants.RefreshButtonAggregator,            
            model, (resp) => {
                this._jqueryService.fillButtonAgregatorArea(resp);
            }, (err) => {
                this._handlerService.Error(err);
            });
    }
    
    setButtonAggregator(model: IButtonAggregatorModel): void {        
        this._buttonAggregatorModel = model;
        this._proxy = this.generateProxy(model);
    }
    //AA
    //genero il modello comprensivo di delegati che verrà usato dalla UI per l'attach vero e proprio
    generateProxy(buttonAggregator: IButtonAggregatorModel): ButtonAggregatorProxy {        
        let prx: ButtonAggregatorProxy = {};
        let relatedService = this._sharedService.getRelatedService();
        //ciclo il modello che arriva dal componente buttonaggregator
        buttonAggregator.Buttons.forEach((btn: IButtonModel) => {
            if (btn.Label != null) {
                prx[btn.Label] = { ...btn };
                prx[btn.Label].relatedService = relatedService;
                //verifico l'esistenza del servizio che sta usando i buttons di una buttonAggregator recuperandolo dallo sharedService
                //lego il delegato <ClientHandlerName> del servizio relatedService(quello caricato dalla pagina)
                if (relatedService != null && btn.ClientHandlerName != null && typeof relatedService[btn.ClientHandlerName] === 'function') {
                    //il nome dei delegati in relatedService deve essere <ClientHandlerName>
                    prx[btn.Label].action = relatedService[btn.ClientHandlerName];
                    //chiedo alla pagina di eseguire l'attach
                    this._sharedService.resolveCrossDelegate(DELEGATES.LAYOUT_ATTACHEVENT_CLICK, prx[btn.Label]);
                }
            };
        });
        return prx;
    }
    
    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService) {
        this._handlerService = handlerService;
        this._apiService = apiService;
        this._sharedService = sharedService;
        this._jqueryService = jqueryService;
    }
}