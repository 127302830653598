import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { ICropperService } from './CropperService';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES} from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { UICONSTANTS } from '../entry';
import { IFileUploadService } from '../Shared/FileUploadService';


export interface IDocumentService extends IBaseComponentService<IDocumentModel> {  
    simpleSearch(searcher: IDocumentSearcher):void;
    fullSearch(searcher: IDocumentSearcher): void;    
    saveDocument(category:string): void;
    setFileForDocument(model: any): void;
    getTags(): void;
}

@injectable()
export class DocumentService extends BaseComponentService<IDocumentModel> implements IDocumentService {
      
    private _fileUploadService: IFileUploadService;
    private _documentResource: string = "DOCUMENT_RESOURCE";

    setFileForDocument(event: any): void {
        this._fileUploadService.setFile(this._documentResource, event);
    } 

    getTags(): void {
        this._apiService.get<any>(Api.DocumentGetTags, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.DOCUMENT_LOAD_TAGS, resp);
        });
    }  

    saveDocument(catagory: string): void {        
        this.getModel("div[data-4eh-form='document-form-" + catagory +"']");
        var model = this._fileUploadService.getModelAndFile(this._model, this._documentResource);
        
        this._apiService.post<any>(Api.DocumentSave, model, (resp) => {
            this._apiService.notifySuccess = false;
            this._apiService.post<any>(Api.DocumentCatalogueReload, {Items:[resp]}, (resp) =>
            {                
                this._sharedService.resolveCrossDelegate(DELEGATES.DOCUMENT_CATALOGUE_LOAD_DATA, resp);
            });            
        });
    }
          
    simpleSearch(searcher: IDocumentSearcher): void {        
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.DocumentSearch, searcher, (resp) => {            
            this._sharedService.resolveCrossDelegate(DELEGATES.DOCUMENT_LOAD_DATA, resp);            
        });
    }

    fullSearch(searcher: IDocumentSearcher): void {        
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.DocumentSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.DOCUMENT_LOAD_DATA, resp);
        });
    }

    submitForm() {
        throw new Error("Method not implemented.");
    }
     
    RenderApi: string;   

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService        
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.FILEUPLOAD_SERVICE) fileUploadService: IFileUploadService
    ) {        
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);         
        this._fileUploadService = fileUploadService;   
    }
}