//AA esporto le costanti per poterne fruire anche nelle view
export const UICONSTANTS =
{
    ID_ALERT_SUCCESS: "feh-alert-success",
    ID_ALERT_ERROR: "feh-alert-error",
    CLASS_ALERT_CONTENT: "feh-alert-content",
    CLASS_AVATAR_IMAGE: "feh-avatar-image",
    CLASS_PROGRESS_DANGER: "progress-bar-danger",
    CLASS_PROGRESS_WARNING: "progress-bar-warning",
    CLASS_PROGRESS_SUCCESS: "progress-bar-primary",
    DATA_FORM_SAVE: "button[data-4eh-form='submit']",
    DATA_FORM_DELETE: "button[data-4eh-form='delete']",
    DATA_FORM_CONFIRM: "button[data-4eh-form='confirm']",
    DATA_FORM_FORM: "div[data-4eh-form='form']",
    DATA_FORM_RECORD: "#edit-record-tab",
    DATA_FORM_ACCOUNTING: "[data-4eh-accounting]",
    DATA_FORM_CONTACT: "#add-contact",
    DATA_FORM_ADDRESS: "#add-address",
    DATA_FORM_REQUESTEDIT: "div[data-4eh-editRequest-form]",
    //DATA_FORM_VALUE: "[data-4eh-form]",
    //FOREH_FORM: "4eh-form",
    BUTTONAGGREGATOR_AREA: "div[data-4eh-container='button-aggregator']",
    ZOOM_IN: "zoom-in",
    ZOOM_OUT: "zoom-out",
    ZOOM_RESET: "reset",
    ID_PAGE_WRAPPER: "page-wrapper",
    CLASS_SK_LOADING: "sk-loading",
    CLASS_SK_SPINNER: "feh-spinner",
    CLASS_HIDDEN: "hidden",
    DATA_PART_REF: "[data-4eh-part-ref='{0}']",
    DATA_ITEM_MODAL_ID: "[data-4eh-item-id]",
    MODAL_BACKDROP: "modal-backdrop",
    //data-4eh-part-ref
    DATA_FORM_RECORD_LANDING: "[data-4eh-form='landing-form']",
    DATA_FORM_RECORD_WIZARD: "[data-4eh-form-wizard]",
    DATA_FORM_RECORD_ANA_HR: "#hr-tab",
    DATA_FORM_RECORD_ANA_PRICING: "#pricing-tab",
    DATA_FORM_RECORD_ANA_CV: "#curriculum-tab",
    DATA_FORM_RECORD_ANA_INFOOPERATIVE: "#infooperative-tab",
    DATA_FORM_RECORD_WIZARD_GEN: "[data-4eh-form-wizard-gen]",
    DATA_FORM_RECORD_WIZARD_CV: "[data-4eh-form-wizard-cv]",
    EDIT_LOCALIZATION: "#edit-localization",
    EMBEDDED_CONTAINER: "#embedded-container",
    CONTAINER_FORLOAD: "#container-forload",
}