import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { DELEGATES } from '../entry';
import { IConfirmModalService } from '../Shared/ConfirmModalService';


export interface IResourceMatchingService extends IBaseComponentService<any> {
    fillCombo(idOrder: string): void;
    simpleSearch(searcher: any): void;
    bindManualOrder(model: any): void;
    freeSearch(searcher: any): void;
    getTraduttoreInfo(id: string): void;
}

@injectable()
export class ResourceMatchingService extends BaseComponentService<any> implements IResourceMatchingService {
    private _modalService: IConfirmModalService;

    getTraduttoreInfo(id:string) {
        this._apiService.notifySuccess = false;
        let url = Api.GetTraduttoreInfo + "?id=" + id;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_TRADUTTOREINFO_MODAL, resp);
        });
    }

    bindManualOrder(model: any): void {
        
        var storedModel = this._modalService.getStoredModel();
        storedModel["IdOrdine"] = model.idOrdine;
        this._apiService.post<any>(Api.ResourceMatchingBindPreventivo, storedModel, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RESOURCEMATCHING_RELOAD_GRID, resp);
        });
    }

    fillCombo(idOrder: string): void {
        //$("select[name='TipoServizio']")
        this._apiService.notifySuccess = false;
        var url = Api.ResourceMatchingGetDetailOrder + "?id=" + idOrder;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RESOURCEMATCHING_FILL_COMBO, resp);
        });
    }
    simpleSearch(searcher: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ResourceMatchingSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RESOURCEMATCHING_LOAD_GRID, resp);
        });
    }

    freeSearch(searcher: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ResourceMatchingFreeSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RESOURCEMATCHING_LOAD_GRID, resp);
        });
    }

    

    initComponent(model: any): void {
    }
    submitForm() {

    }


    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.CONFIRM_MODAL) modalService: IConfirmModalService
    ) {        
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
        this._modalService = modalService;
    }
}