import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
//import * as moment from 'moment';


export interface IPresenceService extends IBaseComponentService<IPresenceModel> {
    stamping(): void;
    reloadTimeToWork(): void;
    searchMyPresences(searcher: IPresenceSearcher): void;
    searchAllPresences(searcher: IPresenceSearcher): void;
    save(model): void;
    remove(id: any): void;
}

@injectable()
export class PresenceService extends BaseComponentService<IPresenceModel> implements IPresenceService {
    save(model: any): void {
        this._apiService.post<any>(Api.SaveStamping, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.PRESENCES_LOAD_STAMPAREA, resp);
        });
    }

    remove(model: any): void {
        this._apiService.post<any>(Api.RemoveStamping, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.PRESENCES_LOAD_STAMPAREA, resp);
        });
    }

    searchMyPresences(searcher: IPresenceSearcher): void {
        this.search(Api.MyPresencesSearch, searcher);
    }

    searchAllPresences(searcher: IPresenceSearcher): void {
        this.search(Api.AllPresencesSearch, searcher);
    }

    private search(url: string, searcher: IPresenceSearcher): void {
        this._apiService.notifySuccess = false;
        debugger;
        if (searcher.Dal != "") {
            let dal = searcher.Dal;
            searcher.Dal = this._sharedService.formatDefaultDate(dal);
        }
        if (searcher.Al != "") {
            let al = searcher.Al;
            searcher.Al = this._sharedService.formatDefaultDate(al);
        }

        this._apiService.post<any>(url, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.PRESENCES_LOAD_DATA, resp);
        });
    }

    stamping(): void {
        this._apiService.get<any>(Api.Stamping, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.TOASTR_INFO, { content: resp.messaggio });
            this._apiService.notifySuccess = false;
            this._apiService.get<any>(Api.StampingOfDay, null, (resp) => {
                this._sharedService.resolveCrossDelegate(DELEGATES.STAMPINGSOFDAY_LOAD_DATA, resp);
            });
            this.reloadTimeToWork();
        });
    }

    reloadTimeToWork(): void {
        this._apiService.get<any>(Api.ReloadTimeToWork, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.TIMETOWORK_LOAD_DATA, resp);
        });
    }

    submitForm() {
        throw new Error("Method not implemented.");
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}