import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';


export interface IAccountService extends IBaseComponentService<IAccountmodel> {
    saveAccount(): void;
}

@injectable()
export class AccountService extends BaseComponentService<IAccountmodel> implements IAccountService {
    
    saveAccount(): void {
        this.getModel();
        let model = this._model;
        $.each($("select[name='assignedRoles'] option:checked"), function (i, item) {
            model["UserModel"].AssignedRoles.push($(this).val());
        });
        // svuoto la rubrica perché crea problemi (essendoci interfacce al salvataggio dell'account)
        model["RecordModel"]["Rubric"] = [];
        model["RecordModel"]["PhysicalAdresses"] = [];
        model["FullUserModel"] = null;
        this._apiService.post<any>(Api.SaveUser, model, (resp) => {
            location.reload();
        });
    }

    submitForm() {
        throw new Error("Method not implemented.");
        //// LT questo serve perché mi deve ricaricare i dati presenti in form
        //// è l'autobind dei poveri
        //this.getModel();
        //this._apiService.post<any>(Api.SavePhysicalRecord, this._model, (resp) => {
        //});
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}