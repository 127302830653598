//AA 16/12/2018
//classe di appoggio per la risoluzione di servizi Ts e l'accesso indiretto al codice di quest'ultimo
import container from "./Wires/Bootstrapper";
import SERVICE_IDENTIFIER from './Wires/Identifiers';
import { ILoginService } from "./Shared/LoginService";
import { ISignalR } from "./Services/SignalR";
import { ISharedService } from "./Shared/SharedService";
import { ICropperService } from "./Services/CropperService";
import { IPasswordService } from "./Services/PasswordService";
import {IProfileService} from "./Services/ProfileService";
import { IJqueryService } from "./Shared/JQueryService";
import { IButtonAggregatorService } from "./Shared/ButtonAggregator/ButtonAggregatorService";
import { IControlPanelService } from "./Shared/ControlPanelService";
import { IConfirmModalService } from "./Shared/ConfirmModalService";
import { IAccountService } from "./Services/AccountService";
import { IRecordService } from "./Services/RecordService";
import { IDocumentService } from "./Services/DocumentService";
import { IETLService } from "./Services/ETLService";
import { IPresenceService } from "./Services/PresenceService";
import { IActivityService } from "./Services/ActivityService";
import { IRequestService } from "./Services/RequestService";
import { IUtilityService } from "./Shared/UtilityService";
import { IContextData } from "./Models/ButtonAggregatorModel";
import { IGeoService } from "./Services/GeoService";
import { IPowerBiService } from "./Shared/PowerBiService";
import { IExportService } from "./Services/ExportService";
import { IScorecardService } from "./Services/ScorecardService";
import { IResourceMatchingService } from "./Services/ResourceMatchingService";
import { ISvgService } from "./Services/SvgService";
import { IRequestSmartOfficeService } from "./Services/RequestSmartOfficeService";
import { IFeedbackService } from "./Services/FeedbackService";
import { IBlazorService } from "./Shared/BlazorService";

export class App {
    static setPageInfo(contextInfo: IContextData) {
        var sharedService = container.get<ISharedService>(SERVICE_IDENTIFIER.SHARED_SERVICE);
        sharedService.setContextData(contextInfo);
    }
    //risolve un servizio Ts attraverso la chiave definita in SERVICEIDENTIFIER
    //le costanti di SERVICEIDENTIFIER sono esposte da entry.ts
    getService(serviceName: string): any {
        //debugger;
        return container.get(serviceName);
    }

    static activityService(model: any): IActivityService {
        //debugger;
        var service = container.get<IActivityService>(SERVICE_IDENTIFIER.ACTIVITY_SERVICE);
        if(model!=null)
            service.initComponent(model)
        return service
    }

    //recupero esplicito di un servizio
    static loginService(): ILoginService {        
        return container.get<ILoginService>(SERVICE_IDENTIFIER.AUTH_SERVICE);        
    }

    static signalRService(): ISignalR {
        return container.get<ISignalR>(SERVICE_IDENTIFIER.SIGNALR);
    }

    static buttonAggregatorService(): IButtonAggregatorService {
        return container.get<IButtonAggregatorService>(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR);
    }

    static profileService(model: IFullUserModel): IProfileService {
        var service = container.get<IProfileService>(SERVICE_IDENTIFIER.PROFILE_SERVICE);
        service.initComponent(model);
        return service;
    }
  
    static etlService(): IETLService {
        return container.get<IETLService>(SERVICE_IDENTIFIER.ETL_SERVICE);                
    }

    static documentService(model: IDocumentModel): IDocumentService {
        var service = container.get<IDocumentService>(SERVICE_IDENTIFIER.DOCUMENT_SERVICE);
        service.initComponent(model);
        return service;
    }
  
    static controlPanelService(): IControlPanelService {
        var service = container.get<IControlPanelService>(SERVICE_IDENTIFIER.CONTROLPANEL_SERVICE);
        return service;
    }

    static sharedService(): ISharedService {
        return container.get<ISharedService>(SERVICE_IDENTIFIER.SHARED_SERVICE);
    }

    static cropperService(): ICropperService {
        return container.get<ICropperService>(SERVICE_IDENTIFIER.CROPPER_SERVICE);
    }
 
    static passwordService(): IPasswordService {
        return container.get<IPasswordService>(SERVICE_IDENTIFIER.PASSWORD_SERVICE);
    }

    static jqueryService(): IJqueryService {
        return container.get<IJqueryService>(SERVICE_IDENTIFIER.JQUERY_SERVICE);
    }

    static confirmModalService(): IConfirmModalService {
        return container.get<IConfirmModalService>(SERVICE_IDENTIFIER.CONFIRM_MODAL);
    }

    static confirmModalServiceWithInit(modalId:string, urlToAction: string): IConfirmModalService {
        var service = this.confirmModalService();
        service.initModalConfirm(modalId, urlToAction);
        return service;
    }

    static accountService(model: IAccountmodel): IAccountService {
        var service = container.get<IAccountService>(SERVICE_IDENTIFIER.ACCOUNT_SERVICE);
        service.initComponent(model);
        return service;
    }

    static recordService(model: IRecordModel): IRecordService {
        var service = container.get<IRecordService>(SERVICE_IDENTIFIER.RECORD_SERVICE);
        if(model!=null)
            service.initComponent(model);
        return service;
    }

    static exportService(): IExportService {
        return container.get<IExportService>(SERVICE_IDENTIFIER.EXPORT_SERVICE);        
    }

    static presenceService(model: IPresenceModel): IPresenceService {
        var service = container.get<IPresenceService>(SERVICE_IDENTIFIER.PRESENCE_SERVICE);
        if (model != null)
            service.initComponent(model);
        return service;
    }

    static requestService(model: IRequestModel): IRequestService {
        var service = container.get<IRequestService>(SERVICE_IDENTIFIER.REQUEST_SERVICE);
        service.initComponent(model);
        return service;
    }

    static utilityService(): IUtilityService {
        var service = container.get<IUtilityService>(SERVICE_IDENTIFIER.UTILITY_SERVICE);
        return service;
    }

    static geoService(): IGeoService {
        var service = container.get<IGeoService>(SERVICE_IDENTIFIER.GEO_SERVICE);
        return service;
    }

    static powerbiService(): IPowerBiService {
        var service = container.get<IPowerBiService>(SERVICE_IDENTIFIER.POWERBI_SERVICE);
        return service;
    }

    static scorecardService(): IScorecardService {
        var service = container.get<IScorecardService>(SERVICE_IDENTIFIER.SCORECARD_SERVICE);
        return service;
    }

    static resourceMatchingService(): IResourceMatchingService {
        var service = container.get<IResourceMatchingService>(SERVICE_IDENTIFIER.RESOURCEMATCHING_SERVICE);
        return service;
    }

    static svgService(): ISvgService {
        var service = container.get<ISvgService>(SERVICE_IDENTIFIER.SVG_SERVICE);
        return service;
    }

    static requestSmartOfficeService(): IRequestSmartOfficeService {
        var service = container.get<IRequestSmartOfficeService>(SERVICE_IDENTIFIER.REQUESTSMARTOFFICE_SERVICE);
        return service;
    }

    static feedbackService(): IFeedbackService {
        var service = container.get<IFeedbackService>(SERVICE_IDENTIFIER.FEEDBACK_SERVICE);
        return service;
    }

    static blazorService(): IBlazorService {
        var service = container.get<IBlazorService>(SERVICE_IDENTIFIER.BLAZOR_SERVICE);
        return service;
    }
}

