import Cropper, * as cropperjs from "cropperjs";
import { injectable } from "inversify";
import 'cropperjs/dist/cropper.css';

export interface ICropperService {
    setCropperImage(jqueryRef: string): void;
    refresh(result: any): void;
    getCroppedCanvas(callback: (blob: Blob) => void, width?:number, height?:number): void;
}

@injectable()
export class CropperService implements ICropperService {
    
    _cropper: Cropper;
    refresh(result: any): void {
        this._cropper.replace(result);
    }
    setCropperImage(jqueryRef: string): void {
        var image = $(jqueryRef)[0] as HTMLImageElement;

        this._cropper = new Cropper(image, {
            aspectRatio: 1 / 1,
            modal: true,
            zoomable: true
        });
    }

    getCroppedCanvas(callback: (blob: Blob) => void, width?: number, height?: number): void {
        this._cropper.crop();
        if (width == null) width = 200;
        if (height == null) height = 200;
        this._cropper.getCroppedCanvas({ width: width, height: height }).toBlob(callback);
    }
}