import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { ICropperService } from '../Services/CropperService';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES} from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';


export interface IProfileService extends IBaseComponentService<IFullUserModel> {
    changeAvatar(model: any): void;
    //saveAvatar(): void;
}

@injectable()
export class ProfileService extends BaseComponentService<IFullUserModel> implements IProfileService {

    submitForm() {       
        // LT questo serve perché mi deve ricaricare i dati presenti in form
        // è l'autobind dei poveri
        let currentLang = this._model.DefaultLanguage;
        let chagePwd = this._model.NewPassword != "" && this._model.OldPassword != "";
        this.getModel();
        this._apiService.post<any>(Api.UpdateUser, this._model, (resp) => {
            if (currentLang != this._model.DefaultLanguage || chagePwd)
                location.reload();
        });
    }
 
    RenderApi: string;

    _imgAvatar: File;

    changeAvatar(event: any): void {
        let input = event.target;

        if (input.files && input.files[0]) {
            this._imgAvatar = input.files[0];
            input.files[0].fil
            var reader = new FileReader();
            var that = this;
            reader.onload = function (e) {
                that._cropperService.refresh(e.target["result"]);  
                that._sharedService.resolveCrossDelegate(DELEGATES.PROFILE_ENABLEAVATAR);
            }
            reader.readAsDataURL(this._imgAvatar);
        }
    }    
    _cropperService: ICropperService;

    // il metodo ritaglia l'immagine e la invia al server per la persistenza
    saveAvatar(): void {
        this._cropperService.getCroppedCanvas((blob) => { 
            const formData = new FormData();
            var avatar = new File([blob], this._imgAvatar.name); // serve per avere il nome originale compresa l'estensione
            formData.append('avatar', avatar);
         
            this._apiService.post<any>(Api.ChangeAvatar, formData, (resp) => {
                if (resp.isValid)
                    this._sharedService.resolveCrossDelegate(DELEGATES.PROFILE_UPDATEAVATAR, resp.avatar);
            });
        });
    }
    //Eventi di esempio attachati ai pulsanti della toolpad
    ChangeAvatarHandler(relatedService: ProfileService): void {
        
        relatedService._cropperService.getCroppedCanvas((blob) => {
            const formData = new FormData();
            var avatar = new File([blob], relatedService._imgAvatar.name); // serve per avere il nome originale compresa l'estensione
            formData.append('avatar', avatar);
        
            relatedService._apiService.post<any>(Api.ChangeAvatar, formData, (resp) => {
                if (resp.isValid)
                    relatedService._sharedService.resolveCrossDelegate(DELEGATES.PROFILE_UPDATEAVATAR, resp.avatar);
            });
        });
        
        
    }


    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.CROPPER_SERVICE) cropperService: ICropperService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        ,@inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {        
        super(apiService, handlerService, buttonAggregatorService, sharedService,jqueryService);
        this._cropperService = cropperService;             
    }
}