import { injectable, inject, traverseAncerstors } from "inversify";
import { DELEGATES } from "../entry";
import { UICONSTANTS } from "../Constants/UIConstants";
import * as pbi from "powerbi-client";
import { TokenType, Permissions, BackgroundType, LayoutType, DisplayOption, ViewMode } from "../../node_modules/powerbi-models";
import { IEmbedConfiguration, Report, IVisualEmbedConfiguration, Visual } from "powerbi-client";

export interface IPowerBiService {
    embeddedReport(model: any, code: string): void;
}

enum EmbeddedType {
    FullReport = 0,
    SingleVisual = 1,
    MultipleVisual = 2
}

@injectable()
export class PowerBiService implements IPowerBiService {
    private powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

    private setConfigBase(model: any): IEmbedConfiguration {
        let filters = []
        if (model.Filters != "" && model.Filters != null)
            filters = JSON.parse(model.Filters);
        let config: IEmbedConfiguration = {
            tokenType: TokenType.Embed,
            accessToken: model.EmbedToken.token,
            embedUrl: model.EmbedUrl,
            id: model.Id,
            permissions: Permissions.Read,
            settings: {
                filterPaneEnabled: false,
                background: BackgroundType.Transparent,
                layoutType: LayoutType.Custom,
                customLayout: {
                    displayOption: DisplayOption.FitToPage
                }
            },
            filters: filters
        };
        return config;
    }

    private reportConfig(model: any, config?: IEmbedConfiguration): IEmbedConfiguration {
        if (config == null)
            config = this.setConfigBase(model);
        config.type = 'report';
        config.pageView = 'fitToWidth';
        config.settings.navContentPaneEnabled = true;
        if (model.PageName == null || model.PageName == "") return config;

        config.pageName = model.PageName;
        config.settings.navContentPaneEnabled = false;
        return config;
    }

    private visualConfig(model: any, visualName: string, embedConfig?: IEmbedConfiguration): IVisualEmbedConfiguration {
        let config: IVisualEmbedConfiguration = embedConfig == null ? this.setConfigBase(model) as IVisualEmbedConfiguration : embedConfig as IVisualEmbedConfiguration;
        config.type = 'visual';
        config.pageName = model.PageName;
        config.visualName = visualName;
        config.settings.navContentPaneEnabled = false;
        return config;
    }

    embeddedReport(model: any, code: string): void {
        let containerId = "[data-embeded='" + code + "']";
        //if (containerId == null) containerId = UICONSTANTS.EMBEDDED_CONTAINER;
        let container = $(containerId)[0];
        let containerForLoad = $("." + code)[0];
        let config = this.reportConfig(model);

        if (model.EmbeddedType == EmbeddedType.FullReport) {
            this.powerbi.embed(container, config);
            return;
        }

        let report: Report = this.powerbi.load(containerForLoad, config) as Report;

        if (model.EmbeddedType == EmbeddedType.SingleVisual) {
            if (sessionStorage.getItem(code) != null) {
                config = JSON.parse(sessionStorage.getItem(code));
                this.powerbi.load(container, config);
                return;
            }

            report.on('loaded', event => {
                let page = report.page(model.PageName);
                page.getVisuals().then((visuals) => {
                    let visual = visuals.find(c => c.title != null && c.title.toLowerCase() == model.VisualTitle.toLowerCase());
                    config = this.visualConfig(model, visual.name, config);
                    this.powerbi.load(container, config);
                    sessionStorage.setItem(code, JSON.stringify(config));
                });
            });

        }
    }
}