import { injectable, inject, traverseAncerstors, tagged } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { ICropperService } from './CropperService';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { UICONSTANTS } from '../entry';
import { IFileUploadService } from '../Shared/FileUploadService';
import { NullLogger } from '../../node_modules/@aspnet/signalr';
import { IUtilityService, UtilityService } from '../Shared/UtilityService';

export interface IRecordService extends IBaseComponentService<IRecordModel> {
    simpleSearch(searcher: IRecordSearcher, type: string): void;
    //fullSearch(searcher: IRecordSearcher): void;
    savePhysicalRecord(): void;
    saveLegalRecord(): void;
    saveContact(): void;
    saveAddress(): void;
    cloneAddress(id): void;
    saveLanding(): void;
    generateNewToken(): void;
    getContact(id: string, contactType: string, idrecord: string): void;
    //saveEmail(): void;
    //savePhone(): void;
    //saveAddress():void
    addFather(): void


    matchStartWith(params: any, data: any): any;
    //validateWizard(step, callBack): Promise<any>;
    //validateWizard(step, model: any): any;    
    validateWizard(step, model: any): Promise<any>;

    saveWizard(model: any): any;
    saveHumanResourceInfo(model: any): any;
    savePricingInfo(model: any): any;
    saveCurriculumInfo(model: any): any;
    saveInfoOperative(model: any): any;
    setFileForCV(model: any): void;
    setFileForAnaHRDocument(model: any): void;
    setFileForAnaPricingDocument(model: any): void;
    setFileForAnaCV(model: any): void;

    // wizard
    simpleRecordSearch(searcher: IRecordSearcher): void;
    getAlert(id: any): void;
    switchToDittaInd(id: string): void;
    saveContactForm(model: any): void;
    getContactForm(model: any): any;
    addJudment(id): void;
    refreshQuickRefHeader(id): void;
    setLocalization(model: any): void;
}

@injectable()
export class RecordService extends BaseComponentService<IRecordModel> implements IRecordService {
    setLocalization(model: any): void {
        this._localizationKeys = model;
    }
    cloneAddress(id: any): void {
        this._apiService.notifySuccess = true;
        this._apiService.post<any>(Api.CloneAddress, { RecordId: id }, (resp) => {
            location.reload();
        });
    }
    addJudment(id): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.AddJudment, { RecordId: id }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_QUICKREF_HANDLEJUDMENTS, resp);
            this._apiService.post<any>(Api.GetJudments, { RecordId: id }, (resp) => {
                this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_QUICKREF_LOADJUDMENTS, resp);
            });
        });
    }
    refreshQuickRefHeader(id): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.RefreshQuickRefHeader, { RecordId: id }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_QUICKREF_REFRESH_QUICKREF_HEADER, resp);
        });
    }
    private _localizationKeys: any;
    private _fileUploadService: IFileUploadService;
    private _utilityService: IUtilityService;
    private _wizardCVResource: string = "WIZARD_CV_RESOURCE";
    private _anaHRDocumentResource: string = "ANA_HR_DOCUMENT_RESOURCE";
    private _anaCVDocumentResource: string = "ANA_CV_DOCUMENT_RESOURCE";
    private _anaPricingDocumentResource: string = "ANA_PRICING_DOCUMENT_RESOURCE";

    saveContactForm(model: any): void {
        this._apiService.notifySuccess = true;
        this._apiService.post<any>(Api.SaveContactForm, model, (resp) => {

        });
    }

    getContactForm(model: any): any {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.GetContactForm, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_CONTACT_LOADFORM_DATA, resp);
        });
    }

    ///curriculum dalla form CV del wizard
    setFileForCV(model: any): void {
        this._fileUploadService.setFile(this._wizardCVResource, model);
    }
    setFileForAnaPricingDocument(model: any): void {
        this._fileUploadService.setFile(this._anaPricingDocumentResource, model);
    }
    //sezione documenti del tab HR delle anagrafiche
    setFileForAnaHRDocument(model: any): void {
        this._fileUploadService.setFile(this._anaHRDocumentResource, model);
    }
    //sezione documenti del tab CV delle anagrafiche
    setFileForAnaCV(model: any): void {
        this._fileUploadService.setFile(this._anaCVDocumentResource, model);
    }

    async validateWizard(step, model: any): Promise<any> {
        var main = this.getWizardModel(model);
        main.Step = step.index;
        var s = step.index;
        main = this._fileUploadService.getModelAndFile(main, this._wizardCVResource);

        this._apiService.notifySuccess = false;
        //this.validationResult = null;
        if (s == 0) {
            return await this._apiService.postAsync<any>(Api.ValidateGeneralTab, main);
            //this._apiService.post<any>(Api.ValidateGeneralTab, main, (resp) => {
            //    this.validationResult = resp;                         
            //});            
        }
        return await null;
    }
    //salvataggio del tab Hr della form anagrafica
    saveHumanResourceInfo(model: any): any {
        var formModel = this.getModel(UICONSTANTS.DATA_FORM_RECORD_ANA_HR);
        formModel.Ticket = model.Ticket;
        formModel.Ruolo = model.Ruolo;
        let dateAssunzioneStr = formModel.DataAssunzione;
        if (dateAssunzioneStr != null && dateAssunzioneStr != "")
            formModel.DataAssunzione = this._sharedService.UTCStringDate(dateAssunzioneStr);
        let dateCessazioneStr = formModel.DataCessazione;
        if (dateCessazioneStr != null && dateCessazioneStr != "")
            formModel.DataCessazione = this._sharedService.UTCStringDate(dateCessazioneStr);
        let dateScadenzaCC = formModel.ScadenzaCartaCredito;
        if (dateScadenzaCC != null && dateScadenzaCC != "")
            formModel.ScadenzaCartaCredito = this._sharedService.UTCStringDate(dateScadenzaCC);

        /*
         let dateOfBirthStr = model["DateOfBirth"];
        if (dateOfBirthStr != null && dateOfBirthStr != "")
            model["DateOfBirth"] = this._sharedService.UTCStringDate(dateOfBirthStr);
         */
        //Eventuale merge dei modelli
        formModel = this._fileUploadService.getModelAndFile(formModel, this._anaHRDocumentResource);
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveAnaTAB, formModel, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_ANA_HR_SUBMIT_SUCCESS, resp);
        });
    }

    savePricingInfo(model: any): any {
        let formModel = this.getModel(UICONSTANTS.DATA_FORM_RECORD_ANA_PRICING);
        let keyValuePair: any = [];
        formModel.ClusterPrezziScale = [];
        $.each($(".cluster-prezzo-scale"), function (i, val) {
            let value = $(this).val() == "" ? null : parseFloat($(this).val());
            if (value != null) {

                keyValuePair.push(
                    {
                        Key: $(this).data("4ehListino"),
                        Value: value
                    });
            }
        });
        formModel.ClusterPrezziScale.push(keyValuePair);
        formModel = this._fileUploadService.getModelAndFile(formModel, this._anaPricingDocumentResource);
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveAnaTAB, formModel, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_ANA_PRICING_SUBMIT_SUCCESS, resp);
        });
    }

    getAlert(id: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.GetAlert, { id }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_DRAWER_OPEN, resp);
        });
    }

    //salvataggio del tab CV della form anagrafica
    saveCurriculumInfo(model: any): any {
        var formModel = this.getModel(UICONSTANTS.DATA_FORM_RECORD_ANA_CV);
        this.map(model, formModel);
        //Eventuale merge dei modelli
        formModel = this._fileUploadService.getModelAndFile(formModel, this._anaCVDocumentResource);
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveAnaTAB, formModel, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_ANA_CV_SUBMIT_SUCCESS, resp);
        });
    }

    //salvataggio del tab InfoOperative della form anagrafica
    saveInfoOperative(model: any): any {
        var formModel = this.getModel(UICONSTANTS.DATA_FORM_RECORD_ANA_INFOOPERATIVE);
        this.mapInfoOperative(model, formModel);
        //Eventuale merge dei modelli        
        formModel = this._fileUploadService.getModelAndFile(formModel, null);
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveAnaTAB, formModel, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_ANA_INFOOPERATIVE_SUBMIT_SUCCESS, resp);
        });
    }

    saveWizard(model: any): any {
        var main = this.getWizardModel(model);
        main.Step = model.Step;
        main = this._fileUploadService.getModelAndFile(main, this._wizardCVResource);

        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveWizard, main, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_SUCCESS, model);
        }, (err) => {
            if (err["data"] == null)
            {
                var msg = "Errore nel salvataggio!";
                if (this._localizationKeys != null && this._localizationKeys != undefined && this._localizationKeys["SAVEERROR"] != undefined)
                    msg = this._localizationKeys["SAVEERROR"];

                this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_ERROR, [{ shortMessage: msg }]);
            }
            else
                this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_ERROR, err["data"]["notifies"]);
        });
    }
    private mapInfoOperative(source: any, dest: any): any {
        dest.ContentTypeCore = source.ContentTypeCore;
        dest.SubjectMitterCore = source.SubjectMitterCore;
        dest.AreaExpertise = source.AreaExpertise;
        dest.RisorsaScartata = source.RisorsaScartata;
        dest.RisorsaPreferita = source.RisorsaPreferita;
        dest.Volume = source.Volume;
        dest.AttivazioneEntro = source.AttivazioneEntro;
        //dest.TestQualifica = source.TestQualifica;
        return dest;
    }

    private map(source: any, dest: any): any {
        dest.LingueConosciute = source.LingueConosciute;
        dest.LinguaMadre = source.LinguaMadre;
        dest.ContentType = source.ContentType;
        dest.ContentTypeCore = source.ContentTypeCore;
        dest.CatUtilizzati = source.CatUtilizzati;
        dest.DTPSoftware = source.DTPSoftware;
        dest.ServiziOfferti = source.ServiziOfferti;
        dest.TitoloDiStudio = source.TitoloDiStudio;
        dest.State = source.State;
        dest.SedeLavoro = source.SedeLavoro;
        dest.AssociazioneDiCategoria = source.AssociazioneDiCategoria;
        dest.MTPE = source.MTPE;
        dest.TipoCertificati = source.TipoCertificati;
        dest.PortaleProfessionisti = source.PortaleProfessionisti;
        dest.TribunaleAsseverazione = source.TribunaleAsseverazione;
        dest.SubjectMitter = source.SubjectMitter;
        dest.SubjectMitterCore = source.SubjectMitterCore;
        dest.PreferredCAT = source.PreferredCAT;
        //dest.Specialization = source.Specialization;
        return dest;
    }

    private getWizardModel(model: any): any {
        var main = this.getModel(UICONSTANTS.DATA_FORM_RECORD_WIZARD);
        this._model = null;//AA resetto il modello interno evitando innesti infiniti dello stesso oggetto        

        main.General = this.getModel(UICONSTANTS.DATA_FORM_RECORD_WIZARD_GEN);
        let dateOfBirthStr = main.General["DataNascita"];
        if (dateOfBirthStr != null && dateOfBirthStr != "") {
            main.General["DataNascita"] = this._sharedService.UTCStringDate(dateOfBirthStr);
        };
        this._model = null;//AA resetto il modello interno evitando innesti infiniti dello stesso oggetto

        main.CV = this.getModel(UICONSTANTS.DATA_FORM_RECORD_WIZARD_CV);
        this.map(model, main.CV);

        this._model = null;//AA resetto il modello interno evitando innesti infiniti dello stesso oggetto                       
        return main;
    }

    generateNewToken(): void {
        this.getModel(UICONSTANTS.DATA_FORM_RECORD_LANDING);

        this._apiService.post<any>(Api.GenerateNewToken, this._model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_SUBMIT_SUCCESS, resp);
        }, (err) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_ALERT_ERROR, err["data"]["notifies"]);
        });
    }

    matchStartWith(params: any, data: any): any {
        if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) {
            var modifiedData = $.extend({}, data, true);
            return modifiedData;
        }
        return null;
    }

    saveLanding(): void {
        this.getModel(UICONSTANTS.DATA_FORM_RECORD_LANDING);

        this._apiService.post<any>(Api.SaveLanding, this._model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_SUBMIT_SUCCESS, resp);
        }, (err) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_SELF_REGISTRATION_ALERT_ERROR, err["data"]["notifies"]);
        });
    }
    initComponent(model: IRecordModel): void {

    }

    getContact(id: string, contactType: string, idrecord: string): void {
        this._apiService.notifySuccess = false;
        //
        let baseUrl = contactType == "address" ? Api.GetAddress : Api.GetContact;
        let url = baseUrl + "?id=" + id + "&idRecord=" + idrecord;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.GET_CONTACT_FORM, resp);
        });
    }

    simpleSearch(searcher: IRecordSearcher, type: string): void {
        this._apiService.notifySuccess = false;
        let url = type.toLowerCase() == "legal" ? Api.LegalSearch : Api.PhysicalSearch;
        this._apiService.post<any>(url, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORD_LOAD_DATA, resp);
        });
    }

    simpleRecordSearch(searcher: IRecordSearcher): void {
        console.log(searcher);
        this._apiService.notifySuccess = false;
        searcher.LaunchSearch = true;
        this._apiService.post<any>(Api.RecordSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORD_LOAD_DATA, resp);
        });
    }

    addFather(): void {
        let fatherId = $("select[name='FatherId'] option:selected").val();
        let sonId = $("input[name='SonId']").val();
        this._apiService.get<any>(Api.PhysicalAddFather, { params: { fatherId: fatherId, sonId: sonId } }, (resp) => {
            location.reload();
            //$("")
        });
    }

    savePhysicalRecord(): void {
        //this.save(Api.SavePhysicalRecord);
        this.save(Api.SaveAnaTAB);
    }
    saveLegalRecord(): void {
        //this.save(Api.SaveLegalRecord);
        this.save(Api.SaveAnaTAB);
    }

    saveContact(): void {
        this.getModel(UICONSTANTS.DATA_FORM_CONTACT);
        let model = {};
        model["RecordId"] = this._model["RecordId"];
        model["Value"] = this._model["GenericValue"];
        model["IsDefault"] = this._model["GenericIsDefault"];
        model["Id"] = this._model["Id"];
        model["ContactType"] = this._model["ContactType"];
        this.callSaveContact(Api.SaveContact, model);
    }

    saveAddress(): void {
        this.getModel(UICONSTANTS.DATA_FORM_ADDRESS);
        let model = {};
        model["RecordId"] = this._model["RecordId"];
        model["Address"] = this._model["AddressValue"];
        model["IsDefault"] = this._model["AddressIsDefault"];
        model["Id"] = this._model["Id"];
        model["Nation"] = this._model["NationValue"];
        model["Region"] = this._model["RegionValue"];
        model["District"] = this._model["DistrictValue"];
        model["City"] = this._model["CityValue"];
        model["PostalCode"] = this._model["PostalCode"];
        model["CategoryId"] = this._model["CategoryId"];
        this.callSaveContact(Api.SaveAddress, model);
    }
    //private saveAddress(model: any): void {
    //    model["Value"] = this._model["AddressValue"];
    //    model["IsDefault"] = this._model["AddressIsDefault"];
    //    this.callSaveContact(Api.SaveAddress, model);
    //}

    private callSaveContact(url: string, model: any): void {
        this._apiService.post<any>(url, model, (resp) => {
            location.href = window.location.href;//resp.redirecturl;
        });
    }

    private save(url: string): void {
        var model = this.getModel(UICONSTANTS.DATA_FORM_RECORD);
        let dateOfBirthStr = model["DateOfBirth"];
        if (dateOfBirthStr != null && dateOfBirthStr != "")
            model["DateOfBirth"] = this._sharedService.UTCStringDate(dateOfBirthStr);

        let dateOfCI = model["ScadenzaCartaDiIdentita"];
        if (dateOfCI != null && dateOfCI != "")
            model["ScadenzaCartaDiIdentita"] = this._sharedService.UTCStringDate(dateOfCI);

        //trasformo il json in FormData per mantenere la compatipilità con il salvataggio degli altri tab.
        //l'api save prevede un parametro FromBody
        model = this._fileUploadService.getFormData(model);
        //this.getModel();
        this._apiService.post<any>(url, model, (resp) => {
            location.href = resp.redirecturl;
        });
    }

    //fullSearch(searcher: ICatalogueSearcher): void {        
    //    this._apiService.notifySuccess = false;
    //    this._apiService.post<any>(Api.RecordSearch, searcher, (resp) => {
    //        this._sharedService.resolveCrossDelegate(DELEGATES.CATALOGUE_LOAD_DATA, resp);
    //    });
    //}

    submitForm() {
        throw new Error("Method not implemented.");
    }

    switchToDittaInd(id: string): void {
        this._apiService.notifySuccess = false;
        this._apiService.get<any>(Api.SwitchToDittaInd, { params: { id } }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.RECORDS_REDIRECT_TO_DITTAINDIVIDUALE, id);
        });
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.FILEUPLOAD_SERVICE) fileUploadService: IFileUploadService
        , @inject(SERVICE_IDENTIFIER.UTILITY_SERVICE) utilityService: IUtilityService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
        this._fileUploadService = fileUploadService;
        this._utilityService = utilityService;
    }
}