import { injectable, inject, traverseAncerstors } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { UICONSTANTS } from '../entry';
//import * as moment from 'moment';

enum CalendarType {
    getMyAndSonsCalendar = 0,
    getCalendarEventsApproved = 1,
    getCalendarDataFor=2
}

export interface IRequestSearcher {
    Type: CalendarType;
    RecordId?: string;
}
export interface IRequestService extends IBaseComponentService<IRequestModel> {
    saveRequest(args: any): void;
    getCalendarData(model?: any): void
    getMyAndSonsCalendarData(model?: any): void
    getCalendarDataFor(model?: any): void
    changeStatus(): void
    search(searcher?: IRequestSearcher): void
    searchCalendar(myAndSons: boolean): void;   
    getModalForRequest(requestId: string): Promise<any>
    createEditRequest(model: any): void;
    eventToggleOn(thisObj: any): void;
    eventToggleOff(thisObj: any): void;
}

@injectable()
export class RequestService extends BaseComponentService<IRequestModel> implements IRequestService {
    eventToggleOn(thisObj: any): void {
        let key = "data-4eh-eventdisplay-type";
        if ($(thisObj).find("span").parent()[0].nodeName.toLowerCase() != "del")
            $(thisObj).find("span").wrap("<del></del>");
        $(thisObj).attr(key, "off");
        $(thisObj).find("input[type='checkbox']").removeAttr("checked");
    }

    eventToggleOff(thisObj: any): void {
        let key = "data-4eh-eventdisplay-type";
        if ($(thisObj).find("span").parent()[0].nodeName.toLowerCase() == "del")
            $(thisObj).find("span").unwrap();
        $(thisObj).attr(key, "on");
        $(thisObj).find("input[type='checkbox']").attr("checked", "checked");
    }

    undoRequest(model: any): void {
        this.notifySuccess = true;
        this._apiService.post<any>(Api.Deleted, model, (resp) => {
            //this.search(this.getSearcher());
        });
    }

    editRequest(model: any): void {
        this.notifySuccess = true;
        this.getModel(UICONSTANTS.DATA_FORM_REQUESTEDIT);
        this.fillRequestModel(model.FasciaOraria);
        model.EndDate = this._model.EndDate;
        model.StartDate = this._model.StartDate;
        
        this._apiService.post<any>(Api.Edited, model, (resp) => {
            //this.search(this.getSearcher());
        });
    }

    createEditRequest(model: any): void {
        this.notifySuccess = true;
        if (model.editRequestType == null || model.editRequestType == undefined)
            throw new Error("valorizzare editRequestType");
        if (model.editRequestType == 1) {
            //edit
            this.getModel(UICONSTANTS.DATA_FORM_REQUESTEDIT);
            this.fillRequestModel(model.FasciaOraria);
            model.EndDate = this._model.EndDate;
            model.StartDate = this._model.StartDate;
        }
        this._apiService.post<any>(Api.NewEditRequest, model, (resp) => {
            //this.search(this.getSearcher());
        });
    }
   
    _storedSearcher?: IRequestSearcher;
       
    //lasciato solo per retrocompatibilità
    searchCalendar(myAndSons: boolean): void {
        this.search({ Type: myAndSons ? CalendarType.getMyAndSonsCalendar : CalendarType.getCalendarEventsApproved });
    }

    async getModalForRequest(requestId: string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            let url = Api.GetModalForRequest + "?requestId=" + requestId;
            this._apiService.get<any>(url, null, (resp) => {                                
                if (resp != null && resp != "") {
                    this._sharedService.resolveCrossDelegate(DELEGATES.CALENDAR_LOAD_MODAL, resp);
                    resolve();
                }
                else
                    reject();
            });
        });
    }

    search(searcher?: IRequestSearcher): void {
        if (searcher == null)
            searcher =  this.getSearcher();
        let model = {
            CalendarView: $("#calendarView").val(),
            StartDate: $("#startCalendarDate").val(),
            EndDate: $("#endCalendarDate").val(),
            //recordId:searcher.RecordId
            recordId:$('[name=userCalendar]').val()
        }
        this.getACalendar(true, this.getURLByCondition(searcher), model);
    }

    changeStatus(): void {
        let id = $("#requestId").val();
        let requestStatus = $("input[name='statusRequest']:checked").val();
        let url = requestStatus == "approve" ? Api.ApproveRequest : Api.RejectRequest;
        //this.notifySuccess = true;
        let that = this;
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(url, { Id: id }, (resp) => {   
            that.search({Type: CalendarType.getMyAndSonsCalendar});
        });

    }
    getCalendarData(model?: any): void {
        this._storedSearcher = { Type:CalendarType.getCalendarEventsApproved };
        this.getACalendar(false, Api.GetCalendarEventsApproved, model);
    }
    getMyAndSonsCalendarData(model?: any): void {
        this._storedSearcher = {
            Type: CalendarType.getMyAndSonsCalendar
        };
        this.getACalendar(false, Api.GetMyAndSonsCalendar, model);
    }
    getCalendarDataFor(model?: any): void {
        this._storedSearcher = {
            Type: CalendarType.getCalendarDataFor,
            RecordId: model.recordId
        };

        this.search(this._storedSearcher);
    }

    private getSearcher(): IRequestSearcher {
        if (this._storedSearcher == null) throw Error("_storedSearcher missing");
        return this._storedSearcher;
    }

    private getStatiChecked(): string[] {
        let stati = [];
        $.each($("[data-4eh-eventdisplay-toggle]"), function (i, val) {
            let key = "data-4eh-eventdisplay-type";
            if ($(this).attr(key) === "on") 
                stati.push($(this).data("4ehEventdisplayToggle"));
        });
        return stati;
    }

    private getURLByCondition(searcher: IRequestSearcher): string {
        if (searcher.Type == CalendarType.getMyAndSonsCalendar)
            return Api.GetMyAndSonsCalendar;
        if (searcher.Type == CalendarType.getCalendarEventsApproved)
            return Api.GetCalendarEventsApproved;
        if (searcher.Type == CalendarType.getCalendarDataFor)
            return Api.GetCalendarDataFor;
        return null;
    }
    private notifySuccess: boolean = false;

    private getACalendar(isReload: boolean, url:string, model?: any): void {
        let stati = this.getStatiChecked();
        if (model == null) model = { CalendarView: "month" };
        model["StatesVisible"] = stati;

        this._apiService.notifySuccess = this.notifySuccess;        
        this._apiService.post<any>(url, model, (resp) => {
            resp["isReload"] = isReload;
            this._sharedService.resolveCrossDelegate(DELEGATES.CALENDAR_LOAD_DATA, resp);
        });
    }

    private fillRequestModel(fasciaOraria) {
        let startDate = this._model.StartDate;
        let endDate = this._model.EndDate != null && this._model.EndDate != "" ? this._model.EndDate : this._model.StartDate;
        this._model.EndDate = this._sharedService.formatDefaultDate(endDate);
        this._model.StartDate = this._sharedService.formatDefaultDate(startDate);
        this._model.FasciaOraria = fasciaOraria;
        
        if (fasciaOraria) {
            this.setPaidLeaveModel();
        }
    }

    saveRequest(args: any): void {
        this.getModel(args.formArea);

        this.fillRequestModel(args.fasciaOraria);
        
        this._apiService.post<any>(Api.RequestSave, this._model, (resp) => {
            this.restoreModel();
            this._jqueryService.forceHideModalbackdrop();
        });
    }

    private restoreModel() {
        this._model.StartDate = null;
        this._model.EndDate = null;
        this._model.FasciaOraria = null;
    }

    private setPaidLeaveModel(): any {
        let modelEndDate = this._model.EndDate != null && this._model.EndDate != "" ? this._model.EndDate : this._model.StartDate;
        let startTime = this._model["StartTime"] == "" ? "00:00:00" : this._model["StartTime"];
        let endTime = this._model["EndTime"] == "" ? "23:59:59" : this._model["EndTime"];
        let startDate = this._model.StartDate + " " + startTime;
        let endDate = modelEndDate + " " + endTime;
        let dS = new Date(startDate);
        dS.setHours(dS.getHours());
        let dE = new Date(endDate);
        dE.setHours(dE.getHours());
        this._model.StartDate = this._sharedService.formatDate(dS, "MM/DD/YY HH:mm", "MM/DD/YY HH:mm");
        this._model.EndDate = this._sharedService.formatDate(dE, "MM/DD/YY HH:mm","MM/DD/YY HH:mm");
        return { StartDate: this._model.StartDate, EndDate: this._model.EndDate, RequestType: this._model.RequestType };
    }


    submitForm() {
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}