import { injectable, inject } from "inversify";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import container from "../Wires/Bootstrapper";
import { ILoginModel } from "../Models/LoginModel";
import { IApiService, ApiService } from "./ApiService";
import { IErrorHandlerService } from "./ErrorHandlerService";
import { IJqueryService } from "./JQueryService";
import { UICONSTANTS } from "../Constants/UIConstants";
import { DELEGATES } from "../entry";
import { ISharedService } from "./SharedService";
import { IButtonAggregatorService } from "./ButtonAggregator/ButtonAggregatorService";

export interface IBaseComponentService<T extends IModelBase | any> {
    //saveForm():void
    RenderApi: string;
    initComponent(model: T): void;
}
@injectable()
export abstract class BaseComponentService<T extends IModelBase | any> implements IBaseComponentService<T> {
    // metodo che viene chiamato nell' onready della pagina. Questo metodo setta il modello e fa l'attach del pulsante salva form
    initComponent(model: T): void {
        this.setModel(model);
        this._jqueryService.attachSaveForm(e => { this.submitForm(); });
    }

    protected getPropertyValue(model: any, propertyName: string): any {
        if (propertyName == undefined || model == undefined) return null;
        if (propertyName.indexOf(".") == -1) return model[propertyName];
        var that = this;
        var arr = propertyName.split(".");
        arr.forEach(function (val) {
            model = that.getPropertyValue(model, val);
            if (model == null) return;
        });

        return model;
    }

    protected setPropertyValue(model: any, propertyName: string, value: any): void {
        if (propertyName == undefined || model == undefined) return;
        if (propertyName.indexOf(".") == -1) model[propertyName] = value;

        var arr = propertyName.split(".");
        let i = 0;
        for (i = 0; i < arr.length - 1; i++) {
            if (arr[i] == "") continue;
            if (model == null) return;
            model = model[arr[i]];
        }

        if (model == null) return;
        model[arr[i]] = value;
    }

    protected setModel(model: T) {
        if (model != undefined && model != null) {
            this._model = model
        };
    }

    protected cloneObject(model: any) {
        var result = {};
        for (var prop in model) {
            if (model[prop] == undefined) continue;
            this.setPropertyValue(result, prop, model[prop]);
        }
        return result;
    }
    protected getModel(id: string = UICONSTANTS.DATA_FORM_FORM): any {
        
        // LT:
        // con questa modifica bindo solamente le property che ho messo "in ascolto" nella form
        // tutto il resto viene ripreso dal modello originale se già bindato la prima volta
        let model: any = this._model == null ? {} : this._model;
        let form: JQuery = $(id);
        let inputs = form.find("input" /*+ UICONSTANTS.DATA_FORM_VALUE*/);
        let textArea = form.find("textarea" /*+ UICONSTANTS.DATA_FORM_VALUE*/);
        let selectes = form.find("select" /*+ UICONSTANTS.DATA_FORM_VALUE*/);
        $.each(inputs, (i, item) => {
            let propName = $(item).attr("name");
            this.setPropertyValue(model, propName, $(item).val());
          
            let type = $(item).attr("type");
            let value = $(item).val();
            if (type == "checkbox")
                value = $(item).prop("checked");
            this.setPropertyValue(model, propName, value);      
        });
        //$.each(inputs, function (i, input) {
        //    model[$(input).data(UICONSTANTS.FOREH_FORM)] = $(input).val();
        //});
        $.each(selectes, (i, item) => {
            let propName = $(item).attr("name");
            // LT è null nel caso di cascade DDL in cui le options non sono ancora state caricate
            //let value = $(item).children(":selected").val() == null ? "" : $(item).children(":selected").val();
            let value = $(item).val() == null ? "" : $(item).val();
            this.setPropertyValue(model, propName, value);            
            //model[$(select).data(UICONSTANTS.FOREH_FORM)] = $(select).children(":selected").val();
        });
        $.each(textArea, (i, item) => {
            let propName = $(item).attr("name");
            let value = $(item).val();
            //if (value == "") value = $(item).val();
            this.setPropertyValue(model, propName, value);
            //model[$(select).data(UICONSTANTS.FOREH_FORM)] = $(select).children(":selected").val();
        });

        this._model = model;
        return model;
    }

    protected _model: T = null;

    abstract submitForm();
    protected _buttonAggregatorService: IButtonAggregatorService = null;

    abstract RenderApi: string;
    protected _apiService: IApiService;
    protected _handlerService: IErrorHandlerService;
    protected _sharedService: ISharedService;
    protected _jqueryService: IJqueryService;

    constructor(@inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService,
        @inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService,
        @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService,
        @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService,
        @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService) {
        this._apiService = apiService;
        this._handlerService = handlerService;
        this._buttonAggregatorService = buttonAggregatorService;
        this._sharedService = sharedService;
        this._jqueryService = jqueryService;

        //if (container.isBoundNamed(SERVICE_IDENTIFIER.TOOLBAR, this.getProviderName()))
        //    this._toolBarService =
        //        container.getNamed<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR, this.getProviderName());   
        //AA
        //i servizi invocati dalle pagine conservato la proprio istanza nello sharedService
        //ad oggi 05/01/2019 solo il buttondAggregator usa questo servizio per l'attach degli eventi
        this._sharedService.setRelatedService(this);
    }


}