import { injectable, inject } from 'inversify';
import { IApiService } from './ApiService';
import * as Api from "../Constants/ApiUrlConstants";
import { IErrorHandlerService } from './ErrorHandlerService';
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { IBaseComponentService, BaseComponentService } from './BaseComponentService';
import { ISharedService } from './SharedService';
import { IJqueryService } from './JQueryService';
import { IButtonAggregatorService } from './ButtonAggregator/ButtonAggregatorService';
import { ControlPanelModel, IControlPanelModel } from '../Models/ControlPanelModel';
import { DELEGATES, UICONSTANTS } from '../entry';
import { relativeTimeRounding, lang } from '../../node_modules/moment';

export interface IControlPanelService extends IBaseComponentService<IControlPanelModel> {
    toggleGrant(id: string, originalCheck: boolean);
    getLocalizationModal(key?: string): void;
    saveLocalization(): void;
    simpleSearch(searcher: any): void;
}

@injectable()
export class ControlPanelService extends BaseComponentService<IControlPanelModel> implements IControlPanelService {
    toggleGrant(id: string, originalCheck: boolean) {
        let arr = id.split('_');
        let model = new ControlPanelModel();
        model.GrantCodeSelected = arr[0];
        model.RoleCodeSelected = arr[1];
        this._apiService.notifySuccess = false;
        this._apiService.showSpinnerLoading = false;
        this._apiService.post<any>(Api.ToggleGrant, model, (resp) => { }, (err) => {
            $("#" + id).prop("checked", originalCheck);
        });
    }

    simpleSearch(searcher: any): void {
        this.search(searcher);
    }

    private search(searcher?: any): void {
        if (searcher == null) searcher = { PageSize: 50 };
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.LocalizationSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOCALIZATION_LOAD_GRID, resp);
        });
    }

    getLocalizationModal(key?: string): void {
        this._apiService.notifySuccess = false;
        let url = Api.EditLocalization;
        if (key != null) url += "?key=" + key;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.GET_EDIT_LOCALIZATION_FORM, resp);
        });
    }

    saveLocalization(): void {
        this.getModel(UICONSTANTS.EDIT_LOCALIZATION);
        let model = {};
        model["Key"] = this._model["Key"];
        model["LocalizationItems"] = [];
        let items = [];

        let result = $(".item input").serializeArray();

        for (var i = 0; i < result.length - 1; i = i + 3) {
            let item = {};
            let language = result[i];
            let value = result[(i + 1)];
            let helper = result[(i + 2)];
            item[language.name] = language.value;
            item[value.name] = value.value;
            item[helper.name] = helper.value;
            items.push(item);
        }

        model["LocalizationItems"] = items;
        this._apiService.post<any>(Api.SaveLocalization, model, (resp) => {
            if (resp.isValid) {
                this.search();
            }

            //location.href = window.location.href;//resp.redirecturl;
        });
    }

    submitForm() {
        throw new Error("Method not implemented.");
    }
    RenderApi: string;


    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}