import { injectable, inject } from 'inversify';

export interface IFileUploadService {
    setFile(key: string, event: any): void;
    getModelAndFile(model: any, key: string): FormData;
    getFile(key: string): FormData;
    getFormData(model: any): FormData;
}

@injectable()
export class FileUploadService implements IFileUploadService {
    resources: { [id: string]: File; } = {};   
    //AA
    //evento proveniente dall'input file
    //key identificativo del file
    setFile(key: string, event: any): void {
        let input = event.target;

        if (input.files && input.files[0]) {
            this.resources[key] = input.files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
            }
            reader.readAsDataURL(this.resources[key]);
        }
    }
    //key identificativo del file
    //model modello da trasportare in FormData
    getModelAndFile(model:any,key: string): FormData {
        const formData = this.fillFormData(model);        
        if (this.resources.hasOwnProperty(key) == false) {                        
            return formData;
        }
        else {            
            formData.append('PostedFile', this.resources[key], this.resources[key].name);
            return formData;
        }
    }      

    getFormData(model: any): FormData {
        return this.fillFormData(model);        
    }

    getFile(key: string): FormData {
        const formData = new FormData();

        if (!this.resources.hasOwnProperty(key))
            return formData;

        formData.append('PostedFile', this.resources[key], this.resources[key].name);
        return formData;
    }      

    ///trasforma il modello in input in form-multipart
    fillFormData(obj: any, form?: any, namespace?: string) {
        var fd = form || new FormData();
        var formKey;

        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {                
                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }
                
                if (typeof obj[property] === 'object' &&
                    !(obj[property] instanceof File) &&
                    !Array.isArray(obj[property])) {

                    if (obj[property] !== null)
                        this.fillFormData(obj[property], fd, formKey);

                }
                else if (Array.isArray(obj[property])) {
                    var that = this;
                                           
                    for (var index = 0; index < obj[property].length; index++) {
                        var item = obj[property][index];                    
                        that.fillFormData(item, fd, formKey);
                    }
                    
                }
                else {                    
                    fd.append(formKey, obj[property]);
                }

            }
        }

        return fd;
    }
}

