import * as Api from "../Constants/ApiUrlConstants";
import * as signalR from "@aspnet/signalr";
import { injectable, inject } from "inversify";
import { IApiService } from "../Shared/ApiService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import IoCContainer from '../Wires/Bootstrapper';
import { IJqueryService } from "../Shared/JQueryService";
import { IUtilityService } from "../Shared/UtilityService";
import { IBlazorService } from "../Shared/BlazorService";

export interface ISignalR {
    Connect(): void;
}

@injectable()
export class SignalR implements ISignalR {
    connection: any;

    _jQueryService: IJqueryService;
    _utilityService: IUtilityService = null;
    _blazorService: IBlazorService = null;

    constructor(@inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jQueryService: IJqueryService,
        @inject(SERVICE_IDENTIFIER.UTILITY_SERVICE) utilityService: IUtilityService,
        @inject(SERVICE_IDENTIFIER.BLAZOR_SERVICE) blazorService: IBlazorService) {
        this._jQueryService = jQueryService;
        this._utilityService = utilityService;
        this._blazorService = blazorService;
    }

    Connect(): void {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/hub")
            .build();
        this.connection.start().catch(err => console.log(err));
        this.connection.on("carrellomodificato", (username: string, message: string) => {
            $(".total-article-to-cart").html(message);
        });
        this.connection.on("testMessage", (username: string, message: string) => {
            alert("Da SignalR " + message);
        });
        this.connection.on("showAlert", (username: string, message: string) => {
            alert(message);
        });
        this.connection.on("testSignalIR", (username: string, message: string) => {
            alert(message);
        });
        this.connection.on("ReloadNotifications", (username: string) => {
            this._utilityService.reloadNotifications();
        });
        this.connection.on("HandleNotifies", (username: string, message: string) => {
            this._utilityService.reloadNotifications();
        });
        this.connection.on("ReloadOrder", (username: string, message: string) => {
            this._blazorService.callCSharpMethod("ReloadOrder", null);
        });
    }
}





