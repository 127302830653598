import { injectable, inject } from "inversify";
import container from "../Wires/Bootstrapper";
import { UICONSTANTS } from "../Constants/UIConstants";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { IApiService, ApiService } from "./ApiService";
import { ISharedService, SharedService } from "./SharedService";
import { DELEGATES, Api } from "../entry";
import { App } from "../app";

export interface IUtilityService {
    loadTabAsync(x: JQuery): void;
    bindTabAsync(): void;
    getSelect2Value(selectName);
    getSelect2SingleValue(selectName);
    matchStartWith(params: any, data: any): any;
    matchContains(params: any, data: any): any;
    isValidGuid(guid: any): boolean;
    reloadNotifications(): void;
    setProcessResultsSelect2(data: any): any;
    readNotification(id): void;
    matchWithGroup(params: any, data: any): any;
}

@injectable()
export class UtilityService implements IUtilityService {
    isValidGuid(guid: any): boolean {
        var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
        return pattern.test(guid);
    }

    matchStartWith(params: any, data: any): any {
        alert('ciao');
        if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) {
            var modifiedData = $.extend({}, data, true);
            return modifiedData;
        }
        return null;
    }

    matchContains(params: any, data: any): any {
        if (data.text.toLowerCase().includes(params.term.toLowerCase())) {
            var modifiedData = $.extend({}, data, true);
            return modifiedData;
        }
        return null;
    }

    setProcessResultsSelect2(data: any): any {
        let res = [];
        $.each(data, function (i, item) {
            res.push({
                id: item.key,
                text: item.value,
                value: item.key,
            });
        });
        return res;
    }

    getSelect2SingleValue(name): any {
        var arr = this.getSelect2Value(name);
        if (arr.length == 0) return null;

        return arr[0];
    }
    getSelect2Value(name): any {
        var result = [];
        $.each($("select[data-plugin='select2'][name='" + name + "']"), function (i, item) {
            result.push($(item).val());
        });
        if (result.length != 0) return result;
        $.each($("select[name='" + name + "']"), function (i, item) {
            result.push($(item).val());
        });
        return result;
    }

    bindTabAsync(): void {
        let that = this;
        $('[data-toggle="tabajax"]').click(function (e) {
            e.preventDefault();
            var tabId = $(this).attr('data-target');
            sessionStorage.setItem('activeTab', tabId);
            that.loadTabAsync($(this));
        });

        $('[data-toggle="tabsync"]').click(function (e) {
            e.preventDefault();
            var tabId = $(this).attr('data-target');
            sessionStorage.setItem('activeTab', tabId);
            that.loadTabAsync($(this));
        });
    }

    loadTabAsync($this: JQuery): void {
        let url = $this.attr('href');
        let targ = $this.attr('data-target');
        if (url != null && url != undefined) {
            this._apiService.get<any>(url, null, (resp) => {
                $(targ).html(resp as string);
            });
        }
        let model = {
            targ: targ,
            that: $this
        };
        this._sharedService.resolveCrossDelegate(DELEGATES.RELOAD_TAB, model);
        //$this.tab('show');
        //$this.on('show.bs.tab', function (e) {
        //    e.target // newly activated tab
        //    e.relatedTarget // previous active tab
        //});
        //$(targ).addClass("in");
    }

    reloadNotifications(): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ReloadNotifications, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.NOTIFICATIONS_RELOAD, resp);
        });
    }

    readNotification(id): void {
        this._apiService.notifySuccess = false;
        this._apiService.get(Api.ReadNotification + "?id=" + id, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.NOTIFICATIONS_OPEN_URL);
        });
    }

    matchWithGroup(params: any, data: any): any {
        debugger;
        data.parentText = data.parentText || "";

        // Always return the object if there is nothing to compare
        if ($.trim(params.term) === '') {
            return data;
        }

        // Do a recursive check for options with children
        if (data.children && data.children.length > 0) {
            // Clone the data object if there are children
            // This is required as we modify the object to remove any non-matches
            var match = $.extend(true, {}, data);

            // Check each child of the option
            for (var c = data.children.length - 1; c >= 0; c--) {
                var child = data.children[c];
                child.parentText += data.parentText + " " + data.text;
                var matches = App.utilityService().matchWithGroup(params, child);

                // If there wasn't a match, remove the object in the array
                if (matches == null) {
                    match.children.splice(c, 1);
                }
            }

            // If any children matched, return the new object
            if (match.children.length > 0) {
                return match;
            }

            // If there were no matching children, check just the plain object
            return App.utilityService().matchWithGroup(params, match);
        }

        // If the typed-in term matches the text of this term, or the text from any
        // parent term, then it's a match.
        var original = (data.parentText + ' ' + data.text).toUpperCase();
        var term = params.term.toUpperCase();


        // Check if the text contains the term
        if (original.indexOf(term) > -1) {
            return data;
        }

        // If it doesn't contain the term, don't return anything
        return null;
    }

    _apiService: IApiService;
    _sharedService: ISharedService;

    constructor(@inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService,
        @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService) {

        this._apiService = apiService;
        this._sharedService = sharedService;

    }
}