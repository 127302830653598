export interface IPwdStrengthModel {
    Value: number;
    Text: string
}
export class PwdStrengthModel implements IPwdStrengthModel {
    Value: number;
    Text: string;

    constructor(text: string, value: number) {
        this.Value = value;
        this.Text = text;
    }
}

export interface IPasswordModel {
    IdInputBox: string;
    IdStrengthProgressBar: string;
    IdStrengthText: string;
    IconPasswordVisible: string;
    IconPasswordInvisible: string;
}

export class PasswordModel implements IPasswordModel {
    IdInputBox: string;
    IdStrengthProgressBar: string;
    IdStrengthText: string;
    IconPasswordVisible: string;
    IconPasswordInvisible: string;
}