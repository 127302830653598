import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { DELEGATES } from '../entry';


export interface IActivityService extends IBaseComponentService<any> {
    saveEffort(args: any): void;
    saveActivity(args: any): void;
    start(id: string, selector: string): void;
    stop(id: string, selector: string): void;
    pause(id: string, selector: string): void;
    setActivityId(id: string): void;
    getAssignedOrderToMe(macroType: string, action: (r: any) => void): any;
    getAssignedJobToMe(orderId: string, jobID: string, action: (r: any) => void): any;
    getCustomJobByActivityType(activityTypeId: string, action: (r: any) => void): any;
    getAlertCascade(alertKey: string): void;
    approveActivities(model: any): void;
    rejectActivities(model: any): void;
    openEditNote(model: any): void;
    saveNote(model: any): void;
    getManualJobsByType(macroType: string, action: (r: any) => void): void;
    reloadApprovalGrid(searcher): void;
    simpleSearch(searcher: any): void;
}

@injectable()
export class ActivityService extends BaseComponentService<any> implements IActivityService {
    reloadApprovalGrid(searcher): void {
        this._apiService.notifySuccess = false;
        let startDate = this._sharedService.formatDefaultDate(searcher.firstDayOfWeek);
        let endDate = this._sharedService.formatDefaultDate(searcher.lastDayOfWeek);
        this._apiService.post<any>(Api.GetActivityApproval, { Cursor: searcher.cursor, FirstDayOfWeek: startDate, LastDayOfWeek: endDate }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITIES_APPROVAL_RELOADDATA, resp);
        });
    }

    simpleSearch(searcher: any): void {
        this._apiService.notifySuccess = false;
        if (searcher.hasOwnProperty('Date') && searcher['Date'] != null && searcher['Date'] != "") {
            let date = searcher["Date"];
            searcher["Date"] = this._sharedService.formatDefaultDate(date);
        }

        //if (searcher["Historical"] == null) {
        //    // caso manage page LT: sono in dubbio se avere una property specifica tipo isManagePage
        //    let date = searcher["Date"];
        //    searcher["Date"] = this._sharedService.formatDefaultDate(date);
        //}
        this._apiService.post<any>(Api.ActivitySearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITY_LOAD_GRID, resp);
        });
    }

    getManualJobsByType(macroType: string, action: (r: any) => void): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.GetManualJobsByType, { AtivityTypeId: macroType }, (resp) => {
            action(resp);
        });
    }
    getAssignedOrderToMe(macroType: string, action: (r: any) => void) {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.GetAllAssignedMe, { ActivityTypeValue: macroType }, (resp) => {
            action(resp);
        });
    }
    saveNote(model: any): void {
        this.getModel();
        model["Note"] = this._model["Note"];
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveNote, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITIES_APPROVAL_LOADDATA, resp);
        });
    }
    openEditNote(model: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.OpenForEditNote, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITIES_APPROVAL_LOAD_EDITNOTE, resp);

        });
    }
    approveActivities(model: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ApproveActivities, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITIES_APPROVAL_LOADDATA, resp);
        });
    }
    rejectActivities(model: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.RejectActivities, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITIES_APPROVAL_LOADDATA, resp);
        });
    }

    getAlertCascade(alertKey: string): void {
        let url = Api.ActivityAlertCascade + "?alertKey=" + alertKey;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITY_LOAD_ALERT_CASCADE, resp);
        });
    }
    getCustomJobByActivityType(activityTypeId: string, action: (r: any) => void) {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.GetCustomJobByActivityType, { AtivityTypeId: activityTypeId }, (resp) => {
            action(resp);
        });
    }

    getAssignedJobToMe(orderId: string, jobID: string, action: (r: any) => void) {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.GetJobsAssignedToMe, { OrderId: orderId, ActivityTypeValue: jobID }, (resp) => {
            action(resp);
        });
    }
    activityId: string;
    setActivityId(id: string): void {
        this.activityId = id;
    }

    saveEffort(args: any): void {
        this.getModel(args.formArea);
        let selector: string = args.selectorPause;
        this._model["Id"] = this.activityId;
        this._apiService.post<any>(Api.ActivitySaveEffort, this._model, (resp) => {
            this.pause(this.activityId, selector);
        });
    }
    start(id: string, selector: string): void {
        this._apiService.post<any>(Api.ActivityStart, { Id: id }, (resp) => {
            $(selector).attr("disabled", "disabled");
            this.handleResponse(resp);
        });
    }
    stop(id: string, selector: string): void {
        this._apiService.post<any>(Api.ActivityStop, { Id: id }, (resp) => {
            $(selector).attr("disabled", "disabled");
            this.handleResponse(resp);
        });
    }
    pause(id: string, selector: string): void {
        this._apiService.post<any>(Api.ActivityPause, { Id: id }, (resp) => {
            $(selector).attr("disabled", "disabled");
            this.handleResponse(resp);
        });
    }

    saveActivity(args: any): void {

        this.getModel(args.formArea);
        this._apiService.post<any>(Api.ActivitySave, this._model, (resp) => {
            this.handleResponse(resp);
        });
    }

    handleResponse(resp): void {

        this._jqueryService.forceHideModalbackdrop();
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ActivitySearch, { Historical: this.isHistorical() }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.ACTIVITY_LOAD_GRID, resp);
        });
    }

    initComponent(model: any): void {
        this.setModel(model);
    }
    submitForm() {

    }

    private isHistorical(): boolean | undefined {
        let his = $("input[name='Historical']").val();
        if (his == null || his == '') return null;
        return JSON.parse(his.toLowerCase());
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}