export const test = 'laura';
export const TestMethod = '/Home/TestMethod';
export const TestRender = ''; // presunto metodo per il recupero del modello iniziale di pagina
export const Login = "/api/AccountApi/Login";
export const Impersonate = "/Account/Impersonate";
export const LogOut = "/Account/Logout";
export const LogOutImpersonate = "/Account/LogoutImpersonate";
export const ChangeRole = "/api/AccountApi/ChangeRole";
export const ChangeAvatar = "/api/Profile/ChangeAvatar";
export const UpdateUser = "/api/Profile/UpdateUser";
export const DocumentSearch = "/Document/Search";
export const DocumentSave = "/Document/Save";
export const DocumentGetTags = "/Document/GetTags";
export const DocumentCatalogueReload = "/Document/GetFiles";
export const RefreshButtonAggregator = "/Home/RefreshButtonAggregator";
export const ToggleGrant = "/ControlPanel/ToggleGrant";
export const EditLocalization = "/ControlPanel/EditLocalization";
export const NewLocalization = "/ControlPanel/NewLocalization";
export const SaveLocalization = "/api/ControlPanel/SaveLocalization";
export const LocalizationSearch = "/ControlPanel/SearchLocalization";
export const PhysicalSearch = "/Physical/Search";
export const RecordSearch = "/Physical/SearchRecord";
export const LegalSearch = "/Legal/Search";
export const SavePhysicalRecord = "/Physical/Save";
export const SaveLegalRecord = "/Legal/Save";
export const SaveUser = "/api/AccountApi/SaveUser";
//export const LegalSaveContact = "/Legal/SaveContact";
//export const PhysicalSaveContact = "/Physical/SaveContact";
export const SaveContact = "/api/ContactsApi/SaveContact";
export const SaveAddress = "/api/ContactsApi/SaveAddress";
export const CloneAddress = "/api/ContactsApi/CloneAddress";
export const GetRegions = "/api/ContactsApi/GetRegions";
export const GetDistricts = "/api/ContactsApi/GetDistricts";
export const GetCities = "/api/ContactsApi/GetCities";


export const GetContact = "/Contacts/GetContact";
export const GetAddress = "/Contacts/GetAddress";
export const Stamping = "/api/PresenceApi/Stamping";
export const SaveStamping = "/api/PresenceApi/Save";
export const RemoveStamping = "/api/PresenceApi/Remove";
export const StampingOfDay = "/Presence/StampingOfDay";
export const ReloadTimeToWork = "/Home/ReloadTimeToWork";
export const MyPresencesSearch = "/Presence/SearchMyPresences";
export const AllPresencesSearch = "/Presence/SearchAllPresences";
export const ActivitySearch = "/Activity/Search";
export const ActivitySave = "/api/ActivityApi/Save";
export const ActivitySaveEffort = "/api/ActivityApi/SaveEffort";
export const ActivityStart = "/api/ActivityApi/Start";
export const GetJobsAssignedToMe = "/api/ActivityApi/GetJobsAssignedToMe";
export const GetCustomJobByActivityType = "/api/ActivityApi/GetCustomJobByActivityType";
export const GetAllAssignedMe = "/api/ActivityApi/GetAllAssignedMe";
export const GetManualJobsByType = "/api/ActivityApi/GetManualJobsByType";
export const GetActivityApproval = "/Activity/GetActivityApproval";
export const ApproveActivities = "/api/ActivityApi/ApproveActivities";
export const SaveNote = "/api/ActivityApi/SaveNote";
export const OpenForEditNote = "/Activity/OpenForEditNote";
export const RejectActivities = "/api/ActivityApi/RejectActivities";
export const ActivityStop = "/api/ActivityApi/Stop";
export const ActivityPause = "/api/ActivityApi/Pause";
export const ActivityAlertCascade = "/Activity/GetAlertCascade";

export const RequestSave = "/api/RequestApi/Save";
export const GetCalendarEventsApproved = "/api/RequestApi/GetCalendarEventsApproved";
export const NewEditRequest = "/api/RequestApi/NewEditRequest";
export const Deleted = "/api/RequestApi/Deleted";
export const Edited = "/api/RequestApi/Edited";
export const GetMyAndSonsCalendar = "/api/RequestApi/GetMyAndSonsCalendar";
export const GetCalendarDataFor = "/api/RequestApi/GetCalendarDataFor";
//export const SearchMyAndSonsCalendarEvent = "/api/RequestApi/SearchMyAndSonsCalendarEvent";
export const ApproveRequest = "/api/RequestApi/Approve";
export const GetModalForRequest = "/Request/GetModalForRequest";
export const RejectRequest = "/api/RequestApi/Reject";
export const ImportRisorse = "/api/ETL/ImportRisorse";
export const ImportRisorseEsterne = "/api/ETL/ImportRisorseEsterne";
export const ImportCapacity = "/api/ETL/ImportCapacity";
export const ImportClienti = "/api/ETL/ImportClienti";
export const ImportOrdini = "/api/ETL/ImportOrdini";
export const ImportOrdiniManuali = "/api/ETL/ImportOrdiniManuali";
export const ImportOrdiniDaAssegnare = "/api/ETL/ImportOrdiniDaAssegnare";
export const ImportQualityScore = "/api/ETL/ImportQualityScore";
export const SetDefaultTraduttori = "/api/ETL/SetDefaultTraduttori";
export const ImportCaricoTraduttori = "/api/ETL/ImportCaricoTraduttori";
export const ImportContentTypeGroup = "/api/ETL/ImportContentTypeGroup";
export const ImportDominioPlunet = "/api/ETL/ImportDominioPlunet";
export const PhysicalAddFather = "/api/PhysicalApi/AddFather";

export const SaveLanding = "/api/RecordApi/SaveLanding";
export const GenerateNewToken = "/api/RecordApi/GenerateNewToken";
export const ValidateGeneralTab = "/api/RecordApi/ValidateGeneralTab";
export const SaveWizard = "/api/RecordApi/SaveWizard";
export const SaveAnaTAB = "/{controller}/Save";
export const GetAlert = "/{controller}/GetAlert";
export const SaveContactForm = "/{controller}/SaveContactForm";
export const GetContactForm = "/{controller}/GetContactForm";
export const SwitchToDittaInd = "/api/RecordApi/SwitchToDittaInd";
export const AddJudment = "/Physical/AddJudment";
export const RefreshQuickRefHeader = "/Physical/RefreshQuickRefHeader";
export const GetJudments = "/Physical/GetJudments";
export const ExportDataConsegna = "/Export/GetDataConsegna";
export const ExportDataFattura = "/Export/GetDataFattura";
export const ExportFatture = "/Export/GetFatture";
export const Import = "/Export/Import";
export const GetFile = "/Export/GetFile";
export const Reload = "/Export/Reload";
export const GetScorecard = "/Scorecard/GetScorecard";
export const GetHistoricalScorecard = "/Scorecard/GetHistoricalScorecard";
export const DeleteScoreItem = "/Scorecard/DeleteScoreItem";
export const AddScoreItem = "/Scorecard/AddScoreItem";
export const SaveScorecard = "/Scorecard/SaveScorecard";
export const ScorecardSearch = "/Scorecard/Search";
export const ResourceMatchingSearch = "/ResourceMatching/Search";
export const ResourceMatchingFreeSearch = "/ResourceMatching/FreeSearch";
export const GetTraduttoreInfo = "/ResourceMatching/GetTraduttoreInfo";
export const ResourceMatchingBindPreventivo = "/api/ResourceMatchingApi/AssociateResource";
export const ResourceMatchingGetDetailOrder = "/api/ResourceMatchingApi/GetDetailOrder";
export const RequestSmartOfficeSearch = "/RequestSmartOffice/Search";
export const RequestSmartOfficeGetDetail = "/RequestSmartOffice/GetDetail";
export const RequestSmartOfficeBook = "/api/RequestSmartOfficeApi/Book";
export const RequestSmartOfficeUnBook = "/api/RequestSmartOfficeApi/UnBook";

export const RemoveAction = "/FeedBack/RemoveAction";
export const AddAction = "/FeedBack/AddAction";
export const SaveFeedback = "/api/FeedBackApi/Save";
export const ReloadActionArea = "/FeedBack/ReloadActionArea";
export const FeedbackSearch = "/FeedBack/Search";
export const FeedbackSearchTasks = "/FeedBack/SearchTasks";
export const ChangeTaskState = "/api/FeedBackApi/ChangeTaskState";
export const ReloadSummary = "/FeedBack/GetSummary";
export const ReloadNotifications = "/Home/GetNotifications";
export const ReadNotification = "/Home/ReadNotification";
export const GetDataByOrder = "/api/FeedBackApi/GetDataByOrder";
export const GetFeedback = "/FeedBack/GetFeedback";
export const OrderFeedbackLookup = "/api/FeedBackApi/GetOrderFeedbackLookup";
export const DeleteFeedback = "/api/FeedBackApi/Delete";