import * as svgPanZoom from 'svg-pan-zoom';
import { injectable, inject } from "inversify";
import { UICONSTANTS} from '../entry';
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { IRequestSmartOfficeService } from './RequestSmartOfficeService';

export interface ISvgService {
    intSvgZoom(ref: string, color4Workstation: any, stationActive: string): void;
}

@injectable()
export class SvgService implements ISvgService {

    _panZoom: SvgPanZoom.Instance;

    intSvgZoom(ref: string, color4Workstation: any, stationActive:string): void {      
        if (!$(ref).length) return;//AA verifica l'esistenza dell'immagine
        this._panZoom = svgPanZoom(ref, {
            zoomEnabled: true,
            controlIconsEnabled: false,
            fit: true,
            center: true,
            minZoom: 0.5
        });

        document.getElementById(UICONSTANTS.ZOOM_IN).addEventListener('click', (ev) => {
            ev.preventDefault();
            this._panZoom.zoomIn();
        });

        document.getElementById(UICONSTANTS.ZOOM_OUT).addEventListener('click', (ev) => {
            ev.preventDefault();
            this._panZoom.zoomOut();
        });

        document.getElementById(UICONSTANTS.ZOOM_RESET).addEventListener('click', (ev) => {
            ev.preventDefault();
            this._panZoom.resetZoom();
            this._panZoom.resetPan();
        });

        setTimeout(() => {
            this._panZoom.resize();
            this._panZoom.fit();
            this._panZoom.center();
        }, 500);

        this.setReferences(ref);
        this.setInitColors(ref, color4Workstation, stationActive);
    }

    private setInitColors(ref: string, color4Workstation: any, stationActive: string) {
        for (var prop in color4Workstation) {
            if (color4Workstation[prop] == null || prop == "$id") continue;
            let value = this.map(color4Workstation[prop].StatoPostazione);
            $(ref + " #" + prop.toLocaleLowerCase()).attr("data-4eh-originalcolor", value).css("fill", value);
        }
        if (stationActive == null || stationActive == "") return;

        $(ref + " #" + stationActive.toLocaleLowerCase()).addClass("active").css("fill", "yellow");
            //.attr("data-4eh-originalcolor", value).css("fill", value);
    }

    private map(statoPosizione: number): string {
        if (statoPosizione == 0) return "#76FF03";
        if (statoPosizione == 1) return "#FF9100";
        if (statoPosizione == 2) return "#C62828";
        return "white";
    }

    private setReferences(ref: string): void {
        $(ref + " .plan-selectable").click((e) => {
            $.each($(".active"), function (i, val) {
                let originalColor = $(this).data("4ehOriginalcolor");
                $(this).css("fill", originalColor).removeClass("active");
            });
            let obj: JQuery = $(e.currentTarget);
            obj.addClass("active").css("fill", "yellow");
            let id = $(e.currentTarget).attr("id");
            let model = this.getModel(id);
            this._service.getdetail(model);
        });

        $(ref + " .plan-selectable").mouseover((e) => {
            let cyan = "#18FFFF";
            let obj: JQuery = $(e.currentTarget);
            obj.css("fill", cyan);
        }).mouseleave((e) => {
            let obj: JQuery = $(e.currentTarget);
            if (!obj.hasClass("active")) {
                let originalColor = obj.data("4ehOriginalcolor");
                obj.css("fill", originalColor);
            }
        });
    }

    private getModel(id:string): any {
        let model = {
            Area: $("#projects-status [name='Area']").val(),
            StartDate: $("#projects-status [name='StartDate']").val(),
            EndDate: $("#projects-status [name='EndDate']").val(),
            Station:id.toLocaleUpperCase()
        };
        return model;
    }

    private _service: IRequestSmartOfficeService


    constructor(@inject(SERVICE_IDENTIFIER.REQUESTSMARTOFFICE_SERVICE) service: IRequestSmartOfficeService) {
        this._service = service;
    }
    //private removeStyle(ref: string) {
    //    $.each($(ref), function () {
    //        $(this).removeAttr("style");
    //    });
    //}

    //private setStyleRefSvg(ref: string, nrRef: string) {
    //    $.each($(ref), function () {
    //        if ($(this)[0].innerHTML == nrRef) {
    //            $(this).css("fill", "blue");
    //            $(this).css("font-size", "1em");
    //        }
    //    });
    //}
}