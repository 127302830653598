import { injectable, inject } from "inversify";
import container from "../Wires/Bootstrapper";
import { UICONSTANTS } from "../Constants/UIConstants";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { JQueryService, IJqueryService } from "./JQueryService";
import * as moment from 'moment';
import { IContextData } from "../Models/ButtonAggregatorModel";
import { IApiService } from "./ApiService";
import { Api, DELEGATES } from "../entry";

export interface ISharedService {
    initMarkup(): void;
    registerCrossDelegate(name: string, delegate: any);
    resolveCrossDelegate(name: string, param?: any);
    setRelatedService(relatedService: any): void;
    getRelatedService(): any;   
    formatDate(date:any, formatFrom: string, formatTo: string): string
    formatDefaultDate(date: any): string
    UTCStringDate(dateStr: string): string
    setContextData(data: IContextData);
    getContextData(): IContextData;

}

@injectable()
export class SharedService implements ISharedService {
    _contextData: IContextData = null;
    
   
    setContextData(data: IContextData) {
        this._contextData=data;
    }
    getContextData(): IContextData {
        return this._contextData;
    }

    formatDate(date:any,formatFrom: string, formatTo: string): string {
        return moment(date, formatFrom).format(formatTo);
    }
    formatDefaultDate(date:any): string {
        return this.formatDate(date,"DD/MM/YYYY", "MM/DD/YYYY");
    }

    UTCStringDate(dateStr: string): string {
        let date = new Date(this.formatDefaultDate(dateStr));
        date.setHours(date.getHours() + 2);
        var result = date.toUTCString();
        if (result == "Invalid Date") return null;
        
        return result;
    }
    
    
    setRelatedService(relatedService: any): void {
        this._relatedService = relatedService;
    }
    getRelatedService() {
        return this._relatedService;
    }
    delegates: { [id: string]: any; } = {};
    _relatedService: any = null;
    registerCrossDelegate(name: string, delegate: any) {
        //if (!this.delegates.hasOwnProperty(name))
        
        this.delegates[name] = delegate;
    }
    resolveCrossDelegate(name: string, param?: any) {
        if (!this.delegates.hasOwnProperty(name))
            return;

        this.delegates[name](param);
    }
    //AA anche questo caso è ambiguo.
    //Lasciamo qui elementi di markup o lasciamo gestire la cosa alla view?
    initMarkup(): void {
        this._jqueryService.managerAlertCustom();
    }
    
    _jqueryService: IJqueryService;
    constructor(@inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService) {
        this._jqueryService = jqueryService;        
    }
}