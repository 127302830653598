import { injectable, inject } from "inversify";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { JQueryService, IJqueryService } from "./JQueryService";
import { ISharedService } from "./SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";

export interface IErrorHandlerService {
    //Error(title: string, content: string): void;
    Error(err: any, title?: string): void;
    SetNotifies(notifies?: string): string;
}

@injectable()
export class ErrorHandlerService implements IErrorHandlerService {
    SetNotifies(notifies?: string): string {
        if (notifies == null) return "";
        return this.setNotifies(notifies);
    }
    //Error(title: string, content: string): void {
    //    this._jQueryService.managerErrorAlert(title, content);
    //}
    
    Error(err: any, title?: string): void { 
        if (typeof (err.response) === "undefined" &&
            typeof (err.data) !== "undefined") {
            err = { response: err };
        }
        if (err.response.data == "" || err.response.data == null) {
            //this._jQueryService.managerErrorAlert(err, title);
            this._sharedService.resolveCrossDelegate(DELEGATES.TOASTR_ERROR, { content:err, title});
            return; 
        }
        let stringNotifies: string;
        if (typeof err.response.data === "string")
            stringNotifies = err.response.data
        if (typeof err.response.data === "object") {
            let notifies = err.response.data.notifies;
            if (notifies == null) {
                notifies = [];
                notifies.push(err.response.data);
            }
            stringNotifies= this.setNotifies(notifies);
        }
        if (stringNotifies == null || stringNotifies == "") return;
        //this._jQueryService.managerErrorAlert(stringNotifies, title);
        this._sharedService.resolveCrossDelegate(DELEGATES.TOASTR_ERROR, { content:stringNotifies, title});
    }

    private setNotifies(notifies: any):string {
        if (notifies == null || notifies.length == 0) return "";

        let stringNotifies: string = notifies.length > 1 ? "<ul>" : "<ul class='list-inline'>";
        $.each(notifies, function (i, item) {
            let shortMsg = item.shortMessage == null ? (item.serverMessage == null ? item.ShortMessage : item.serverMessage) : item.shortMessage;
            stringNotifies += "<li>" + shortMsg + "</li>";
        });
        stringNotifies += "</ul>";
        return stringNotifies;
    }

    _jQueryService: IJqueryService;
    _sharedService: ISharedService;

    constructor(@inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jQueryService: IJqueryService, @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService) {
        this._jQueryService = jQueryService;
        this._sharedService = sharedService;
    }
}
