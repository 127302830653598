import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { DELEGATES } from '../entry';


export interface IRequestSmartOfficeService extends IBaseComponentService<any> {
    book(model: any): any;
    unbook(id: string): any;
    getdetail(model: any): any;
    search(model: any): any;
}

@injectable()
export class RequestSmartOfficeService extends BaseComponentService<any> implements IRequestSmartOfficeService {
    book(model: any): any {
        this._apiService.notifySuccess = false;
        model = this.setSearcherDates(model);
        this._apiService.post<any>(Api.RequestSmartOfficeBook, model, (resp) => {
            if (resp.isValid)
                this.reload();
            //this._sharedService.resolveCrossDelegate(DELEGATES.REQUESTSMARTOFFICE_BOOK, resp);
        });
    }

    private reload() {
        let startDate = $("#projects-status [name='StartDate']").val();
        let endDate = $("#projects-status [name='EndDate']").val();
        let modelSearch = {
            Area: $("#projects-status [name='Area']").val(),
            StartDate: startDate,
            EndDate: endDate,
            Station: $("#container-smartofficedetail [name='Station']").val()
        };
        let modelDetail = {
            Area: $("#projects-status [name='Area']").val(),
            StartDate: startDate,
            EndDate: endDate,
            Station: $("#container-smartofficedetail [name='Station']").val()
        };
        this.callSearch(modelSearch, false);
        this.getdetail(modelDetail);
    }

    unbook(id: string): any {
        this._apiService.notifySuccess = false;
        let url = Api.RequestSmartOfficeUnBook + "?id=" + id;
        this._apiService.get<any>(url, null, (resp) => {
            if (resp.isValid)
                this.reload();
            //this._sharedService.resolveCrossDelegate(DELEGATES.REQUESTSMARTOFFICE_UNBOOK, resp);
        });
    }

    getdetail(model: any): any {
        this._apiService.notifySuccess = false;
        model = this.setSearcherDates(model);
        this._apiService.post<any>(Api.RequestSmartOfficeGetDetail, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.REQUESTSMARTOFFICE_GETDETAIL, resp);
        });
    }

    private setSearcherDates(searcher: any): any {
        let startDate: string = "";
        let endDate: string = "";
        if (searcher["StartDate"] != null && searcher["StartDate"] != "") {
            let strDate: string = searcher["StartDate"];
            startDate = this._sharedService.formatDefaultDate(strDate);
        }

        if (searcher["EndDate"] != null && searcher["EndDate"] != "") {
            let strDate: string = searcher["EndDate"];
            endDate = this._sharedService.formatDefaultDate(strDate);
        }
        searcher["StartDate"] = startDate;
        searcher["EndDate"] = endDate;
        return searcher;
    }

    search(model: any): any {
        this.callSearch(model, true);
    }

    private callSearch(model: any, forceLoadDetail: boolean) {
        this._apiService.notifySuccess = false;
        model = this.setSearcherDates(model);
        this._apiService.post<any>(Api.RequestSmartOfficeSearch, model, (resp) => {
            let result = {
                content: resp,
                forceLoadDetail: forceLoadDetail
            }
            this._sharedService.resolveCrossDelegate(DELEGATES.REQUESTSMARTOFFICE_SEARCH, result);
        });
    }


    initComponent(model: any): void {
    }
    submitForm() {

    }


    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}