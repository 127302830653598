const SERVICE_IDENTIFIER = {
    BUTTON_AGGREGATOR: Symbol.for("ButtonAggregator"),
    TEST_PAGE: Symbol.for("TEST_PAGE"),
    ERROR_HANDLER_SERVICE: Symbol.for("ErrorHandler_Action"),  
    API_SERVICE: Symbol.for("ApiService"),
    AUTH_SERVICE: Symbol.for("AuthService"),  
    SIGNALR: Symbol.for("SignalR"),  
    PROFILE_SERVICE: Symbol.for("ProfileService"),  
    CATALOGUE_SERVICE: Symbol.for("CatalogueService"),  
    DOCUMENT_SERVICE: Symbol.for("DocumentService"),  
    ETL_SERVICE: Symbol.for("ETLService"),  
    CART_SERVICE: Symbol.for("CartService"),  
    BOARD_SERVICE: Symbol.for("BoardService"),  
    FILEUPLOAD_SERVICE: Symbol.for("FileUploadService"),  
    CONTROLPANEL_SERVICE: Symbol.for("ControlPanelService"),  
    SHARED_SERVICE: Symbol.for("SharedService"),  
    JQUERY_SERVICE: Symbol.for("JQueryService"),  
    CROPPER_SERVICE: Symbol.for("CropperService"),  
    SVG_SERVICE: Symbol.for("SvgService"),  
    PASSWORD_SERVICE: Symbol.for("PasswordService"),  
    ORDER_SERVICE: Symbol.for("OrderService"),  
    PART_SERVICE: Symbol.for("PartService"),  
    CONFIRM_MODAL: Symbol.for("ConfirmModalService"),  
    ACCOUNT_SERVICE: Symbol.for("AccountService"),  
    RECORD_SERVICE: Symbol.for("RecordService"),  
    PRESENCE_SERVICE: Symbol.for("PresenceService"),  
    ACTIVITY_SERVICE: Symbol.for("ActivityService"),
    REQUEST_SERVICE: Symbol.for("RequestService"),
    UTILITY_SERVICE: Symbol.for("UtilityService"),
    GEO_SERVICE: Symbol.for("GeoService"),
    POWERBI_SERVICE: Symbol.for("PowerBiService"),
    EXPORT_SERVICE: Symbol.for("ExportService"),
    SCORECARD_SERVICE: Symbol.for("ScorecardService"),
    RESOURCEMATCHING_SERVICE: Symbol.for("ResourceMatchingService"),
    REQUESTSMARTOFFICE_SERVICE: Symbol.for("RequestSmartOfficeService"),
    FEEDBACK_SERVICE: Symbol.for("FeedbackService"),
    BLAZOR_SERVICE: Symbol.for("BlazorService"),
};

export default SERVICE_IDENTIFIER;