import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { injectable, inject } from 'inversify';
import { IErrorHandlerService } from './ErrorHandlerService';
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { IAuthService } from './AuthService';
import { ILoginService } from './LoginService';
import { IJqueryService } from './JQueryService';
import { ISharedService } from './SharedService';
import { DELEGATES } from "../Constants/DelegateConstants";
import { IScorecardService } from '../Services/ScorecardService';

export interface IBlazorService {

    //AA se c'è un sistema migliore usiamolo
    //se true notifica l'utente del completamente dell'operazione
    notifySuccess: boolean;
    // se true visualizza lo spinner
    showSpinnerLoading: boolean;
    SetNotifies(notifies?: string): string;
    Error(err: any, title?: string): void;
    OpenScoreCardPop(id: string, editable?: boolean): void;
    showLoading(): void;
    hideLoading(): void;
    addComponentReference(component): void;
    callCSharpMethod(method, param): void;
    DownloadFile(filename, contentType, content);
    SetCrossValue(model: any): void;
    GetCrossValue(): any;
    ActivateSelect2(): void;
    AttachSelect2Event(key: string): void;
    GoTo(url: string): void;
}

@injectable()
export class BlazorService implements IBlazorService {
    ActivateSelect2(): void {
        setTimeout("$('[data-plugin=\"select2\"]').select2();", 1);
    }

    GoTo(url: string): void {
        debugger;
        //location.href = "/";
        location.href = url;
    }

    callCSharpMethod(method: any, param: any): void {
        if (BlazorService.reference == null)
            throw "Impossibile chiamare il metodo " + method;
        if (param != null)
            BlazorService.reference["invokeMethodAsync"](method, param);
        else
            BlazorService.reference["invokeMethodAsync"](method);
    }

    AttachSelect2Event(key: string): void {
        var F = new Function("$('[data-key=\"" + key + "\"]').select2();$('[data-key=\"" + key + "\"]').off('select2:select');$('[data-key=\"" + key + "\"]').on('select2:select', function (e) {FeH.App.blazorService().callCSharpMethod(\"ContentTypeGroupChange\", e.params.data.id);});");
        F();
    }

    addComponentReference(component: any): void {
        BlazorService.reference = component;
    }
    static reference: object;
    notifySuccess: boolean;
    showSpinnerLoading: boolean;
    _errorHandlerService: IErrorHandlerService;
    _jqueryService: IJqueryService;
    _sharedService: ISharedService;
    _scoreCardService: IScorecardService;
    constructor(
        @inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) ErrorHandlerService: IErrorHandlerService,
        @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService,
        @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService,
        @inject(SERVICE_IDENTIFIER.SCORECARD_SERVICE) scoreCardService: IScorecardService) {

        this._errorHandlerService = ErrorHandlerService;
        this._jqueryService = jqueryService;
        this._sharedService = sharedService;
        this._scoreCardService = scoreCardService;

        this.notifySuccess = true;
        this.showSpinnerLoading = true;
    }

    SetNotifies(notifies?: string): string {
        return this._errorHandlerService.SetNotifies(notifies);
    }
    Error(err: any, title?: string): void {
        this._errorHandlerService.Error(err, title);
    }

    SetCrossValue(model: any): void {
        $("#crossField").val(JSON.stringify(model));
    }
    GetCrossValue(): any {
        var item = $("#crossField").val();
        if (item === null || item == '') return null;
        return JSON.parse($("#crossField").val());
    }

    OpenScoreCardPop(id: string, editable?: boolean): void {
        try {
            this._scoreCardService.openDetailPop(id, editable);
            //window["CloseModal"]("#scorecard");  
        }
        catch (ex) {
            alert(ex);
        }
    }
    showLoading(): void {
        this._jqueryService.showLoading();
    }
    hideLoading(): void {
        this._jqueryService.hideLoading();
    }

    DownloadFile(filename, contentType, content) {
        // Blazor marshall byte[] to a base64 string, so we first need to convert the string (content) to a Uint8Array to create the File
        const data = content//;this.base64DecToArr(content,null);

        // Create the URL
        const file = new File([data], filename, { type: contentType });
        const exportUrl = URL.createObjectURL(file);

        // Create the <a> element and click on it
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = exportUrl;
        a.download = filename;
        a.target = "_self";
        a.click();

        // We don't need to keep the url, let's release the memory
        URL.revokeObjectURL(exportUrl);
    }

    base64DecToArr(sBase64, nBlocksSize) {
        var
            sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ""),
            nInLen = sB64Enc.length,
            nOutLen = nBlocksSize ? Math.ceil((nInLen * 3 + 1 >> 2) / nBlocksSize) * nBlocksSize : nInLen * 3 + 1 >> 2,
            taBytes = new Uint8Array(nOutLen);

        for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
            nMod4 = nInIdx & 3;
            nUint24 |= this.b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
            if (nMod4 === 3 || nInLen - nInIdx === 1) {
                for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
                    taBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
                }
                nUint24 = 0;
            }
        }
        return taBytes;
    }

    b64ToUint6(nChr) {
        return nChr > 64 && nChr < 91 ? nChr - 65 : nChr > 96 && nChr < 123 ? nChr - 71 : nChr > 47 && nChr < 58 ? nChr + 4 : nChr === 43 ? 62 : nChr === 47 ? 63 : 0;
    }
}

