import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { DELEGATES } from '../entry';
import { IUtilityService } from '../Shared/UtilityService';

export interface IFeedbackService extends IBaseComponentService<any> {
    simpleSearch(searcher: any): void;
    getFeedback(id: string, scorecardType: string): void
    deleteAction(id: string): void
    addAction(id: string): void
    saveFeedback(inputModel: any): void
    search(searcher: any): void 
    searchTasks(searcher: any): void 
    changeTastState(model: any): void
    reloadSummary(): void;
    getDataByOrder(order: number): any;
    getFeedbackModal(id: string): any;
    deleteItem(id: string): any;
    removeAction(id: string, feedbackId: string): void;
}

@injectable()
export class FeedbackService extends BaseComponentService<any> implements IFeedbackService {
    deleteItem(id: string) {
        this._apiService.notifySuccess = false;
        this._apiService.get<any>(Api.DeleteFeedback + '?id=' + id, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_SEARCH,1);
        });
    }
    _utilityService: IUtilityService = null;
    reloadSummary(): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ReloadSummary, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_TASK_LOAD_SUMMARY, resp);
        });
    }

    getDataByOrder(order: number): any {
        this._apiService.notifySuccess = false;
        this._apiService.get<any>(Api.GetDataByOrder + '?order=' + order, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_LOAD_BY_ORDER, resp);
        });
    }

    getFeedbackModal(id: string) {
        this._apiService.notifySuccess = false;
        let url = Api.GetFeedback + "?id=" + id ;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_FEEDBACK_MODAL, resp);
        });
    }

    changeTastState(model: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ChangeTaskState, model, (resp) => {
            this.reloadSummary();
            //this._utilityService.reloadNotifications();
            this._apiService.post<any>(Api.ReloadActionArea, { id: model.Thread}, (resp) => {
                this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_TASK_SEARCH, 1);
            });
        });
    }

    searchTasks(searcher: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.FeedbackSearchTasks, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_TASK_LOAD_GRID, resp);
        });
    }

    search(searcher: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.FeedbackSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_LOAD_GRID, resp);
        });
    }

    addAction(id:string) {
        this._apiService.notifySuccess = false;
        debugger;
        this._apiService.post<any>(Api.AddAction, {Id:id}, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_ADD_NEW_ACTION, resp);            
        });
    }

    removeAction(id: string, feedbackId:string) {
        this._apiService.notifySuccess = false;
        debugger;
        this._apiService.post<any>(Api.RemoveAction, { Id: id, Thread: feedbackId }, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_RELOAD_ACTION_AREA, resp);
        });
    }

    getFeedback(id: string, scorecardType: string) {
        //this._apiService.notifySuccess = false;
        //let url = Api.GetScorecard + "?id=" + id + "&type=" + scorecardType;
        //this._apiService.get<any>(url, null, (resp) => {
        //    this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, resp);
        //});
    }

    deleteAction(id: string) {
        //this._apiService.notifySuccess = false;
        //let url = Api.DeleteScoreItem + "?id=" + id;
        //this._apiService.get<any>(url, null, (resp) => {
        //    this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, resp);
        //});
    }

    isValidModel(aModel) {      
        return aModel.hasOwnProperty('TypeId');
    }
    saveFeedback(inputModel:any) {
        this._model = null;
        var actionModel = this.getModel("[data-4eh-fbaction-item]");
        
        this._model = null;
        var headModel = this.getModel("[data-4eh-fbform]");
        if (this.isValidModel(actionModel)) {
            headModel["Tasks"] = [];
            headModel["Tasks"].push(actionModel);         
        }        
        headModel["Rating"] = inputModel.rating;
        
        if (headModel["DataRicezione"] != null && headModel["DataRicezione"] != undefined && headModel["DataRicezione"] != "") {
            headModel["DataRicezione"] = this._sharedService.formatDefaultDate(headModel["DataRicezione"]);
        }
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveFeedback, headModel, (resp) => {
            this._apiService.post<any>(Api.ReloadActionArea, headModel, (resp) => {
                this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_RELOAD_ACTION_AREA, resp);
            });
            this._sharedService.resolveCrossDelegate(DELEGATES.FEEDBACK_SAVE, null);
        });       

    }

    simpleSearch(searcher: any): void {
        //this._apiService.notifySuccess = false;
        //this._apiService.post<any>(Api.ScorecardSearch, searcher, (resp) => {
        //    this._sharedService.resolveCrossDelegate(DELEGATES.SCORECARD_LOAD_GRID, resp);
        //});
    }



    initComponent(model: any): void {
    }
    submitForm() {

    }


    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.UTILITY_SERVICE) utilityService: IUtilityService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
        this._utilityService = utilityService;
    }
}