import { injectable, inject, traverseAncerstors, tagged } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { ICropperService } from './CropperService';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { UICONSTANTS } from '../entry';
import { IFileUploadService } from '../Shared/FileUploadService';
import { NullLogger } from '../../node_modules/@aspnet/signalr';
import { IUtilityService, UtilityService } from '../Shared/UtilityService';

export interface IGeoService extends IBaseComponentService<IModelBase> {

    cascadeRegion(nation: string, nameInput: string): Promise<any>;
    cascadeDistrict(region: string, nameInput: string): Promise<any>;
    cascadeCity(district: string, nameInput: string): Promise<any>;

    fillCombo(model): Promise<any>;

    // wizard
    changeLocalizazionInputType(model: any): void;
    fillComboGeoLoc(model): Promise<any>;
}

@injectable()
export class GeoService extends BaseComponentService<IModelBase> implements IGeoService {

    changeLocalizazionInputType(model: any): void {
        let val = model.val;
        if (val == null) return;
        let classFieldInput = model.classFieldInput;
        let classFieldSelect = model.classFieldSelect;
        let nameFirstCascadeChild = model.nameFirstCascadeChild;


        if (val.toLowerCase() == "italia") {
            $(classFieldInput).addClass("hidden");
            $(classFieldInput + ">input").removeAttr("name");
            $(classFieldSelect).removeClass("hidden");
            $.each($(classFieldSelect + ">select"), function (i, val) {
                var name = $(this).data("4ehName");
                $(this).attr("name", name);
            });
            $(classFieldSelect + ">select").empty();
            this.cascadeRegion(val, nameFirstCascadeChild);
        }
        else {
            $(classFieldSelect).addClass("hidden");
            $(classFieldSelect + ">select").removeAttr("name");
            $(classFieldInput).removeClass("hidden");
            $.each($(classFieldInput + ">input"), function (i, val) {
                var name = $(this).data("4ehName");
                $(this).attr("name", name);
            });
        }
    }


    initComponent(model: IModelBase): void {

    }

    async cascadeRegion(nation: string, nameInput: string): Promise<any> {
        return this.cascade(Api.GetRegions, nameInput, nation);
    }
    async cascadeDistrict(region: string, nameInput: string): Promise<any> {
        return this.cascade(Api.GetDistricts, nameInput, region);
    }
    async cascadeCity(district: string, nameInput: string): Promise<any> {
        return this.cascade(Api.GetCities, nameInput, district);
    }

    private async cascade(url: string, name: string, value: string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this._apiService.get<any>(url, { params: { name: value } }, (resp) => {
                this.renderSelectOption(resp, name);
                resolve();
            });
        });
    }

    async fillCombo(model): Promise<any> {
        let result = [];
        let naz: string = model.Nation;
        let modelResult = {
            result: result
        };

        let type = naz == null || naz.toLowerCase() != "italia" ? "input" : "select";
        let keyRegion = "RegionValue";
        let keyDistrict = "DistrictValue";
        let keyCity = "CityValue";
        if (naz == null || naz.toLowerCase() != "italia") {
            result.push({ key: keyRegion, value: model.Region, type: type });

            result.push({ key: keyDistrict, value: model.District, type: type });

            result.push({ key: keyCity, value: model.City, type: type });
            this._sharedService.resolveCrossDelegate(DELEGATES.TRIGGER_GEOLOC, modelResult);
            return;
        }

        await this.cascadeRegion(model.Nation, keyRegion).then(() => {
            result.push({ key: keyRegion, value: model.Region, type: type });
        });

        if (model.Region != null) {
            await this.cascadeDistrict(model.Region, keyDistrict).then(() => {
                result.push({ key: keyDistrict, value: model.District, type: type });
            });

        }
        if (model.District != null) {
            await this.cascadeCity(model.District, keyCity).then(() => {
                result.push({ key: keyCity, value: model.City, type: type });
            });
        }

        this._sharedService.resolveCrossDelegate(DELEGATES.TRIGGER_GEOLOC, modelResult);

    }

    async fillComboGeoLoc(model): Promise<any> {
        let modelRes = {
            naz: { name: "", value: model.ResNaz },
            reg: { name: "ResReg", value: model.ResReg },
            prov: { name: "ResProv", value: model.ResProv },
            citta: { name: "ResCitta", value: model.ResCitta },
            cap: { name: "ResCap", value: model.ResCap },
            via: { name: "ResVia", value: model.ResVia }
        };
        let modelDom = {
            naz: { name: "", value: model.DomNaz },
            reg: { name: "DomReg", value: model.DomReg },
            prov: { name: "DomProv", value: model.DomProv },
            citta: { name: "DomCitta", value: model.DomCitta },
            cap: { name: "DomCap", value: null },
            via: { name: "DomVia", value: null }
        };
        let modelSede = {
            naz: { name: "", value: model.SedeNaz },
            reg: { name: "SedeReg", value: model.SedeReg },
            prov: { name: "SedeProv", value: model.SedeProv },
            citta: { name: "SedeCitta", value: model.SedeCitta },
            cap: { name: "SedeCap", value: model.SedeCap },
            via: { name: "SedeVia", value: model.SedeVia }
        };
        await this.fillComboWizard(modelRes, null, model.SedeEqualsRes);
        await this.fillComboWizard(modelDom, model.DomEqualsRes);
        await this.fillComboWizard(modelSede);
    }

    private async fillComboWizard(model, domEqualsRes?: boolean, sedeEqualsRes?: boolean): Promise<any> {
        let result = [];
        let naz: string = model.naz.value;
        let modelResult = {
            result: result,
            domEqualsRes: domEqualsRes,
            sedeEqualsRes: sedeEqualsRes
        };

        result.push({
            key: model.cap.name,
            value: model.cap.value,
            type: "input"
        });

        result.push({
            key: model.via.name,
            value: model.via.value,
            type: "input"
        });

        let type = naz == null || naz.toLowerCase() != "italia" ? "input" : "select";

        if (naz == null || naz.toLowerCase() != "italia") {
            result.push({
                key: model.reg.name,
                value: model.reg.value,
                type: type
            });

            result.push({
                key: model.prov.name,
                value: model.prov.value,
                type: type
            });

            result.push({
                key: model.citta.name,
                value: model.citta.value,
                type: type
            });
            this._sharedService.resolveCrossDelegate(DELEGATES.TRIGGER_GEOLOC, modelResult);
            return;
        }

        result.push({
            key: model.reg.name,
            value: model.reg.value,
            type: type
        });
        if (model.reg.value != null) {

            await this.cascadeDistrict(model.reg.value, model.prov.name).then(() => {
                result.push({
                    key: model.prov.name,
                    value: model.prov.value,
                    type: type
                });
            });

        }
        if (model.prov.value != null) {
            await this.cascadeCity(model.prov.value, model.citta.name).then(() => {
                result.push({
                    key: model.citta.name,
                    value: model.citta.value,
                    type: type
                });
            });
        }

        this._sharedService.resolveCrossDelegate(DELEGATES.TRIGGER_GEOLOC, modelResult);
    }

    private renderSelectOption(list: any, selectName: string): void {
        let opts = "";
        let $select = $("select[name='" + selectName + "']");
        $select.find('option').remove();
        $.each(list, function (i, item) {
            //var val = item.valueval.replace(/'/g, "\\'")
            opts += "<option value=\"" + item.value + "\">" + item.text + "</option>";
        });

        $select.append(opts);
    }

    submitForm() {
        throw new Error("Method not implemented.");
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}