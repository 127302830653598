import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { DELEGATES, UICONSTANTS } from '../entry';
import { IFileUploadService } from '../Shared/FileUploadService';
import { IUtilityService } from '../Shared/UtilityService';


export interface IExportService extends IBaseComponentService<any> {
    getDataConsegna(): void;
    getDataFattura(): void;
    getFatture(): void;
    setFileForImport(model: any): void;
    load(model: any): void;
    get(model: any): void;
}

@injectable()
export class ExportService extends BaseComponentService<any> implements IExportService {
    get(model: any): void {
        this._apiService.notifySuccess = false;

        location.href = Api.GetFile + '?code=' + model.code;
        var that = this;
        setTimeout(function () {
            that._apiService.post<any>(Api.Reload, model, (resp) => {
                that._sharedService.resolveCrossDelegate(DELEGATES.IMPORT_RELOAD_SINGLEITEM, resp);                           
            });            
        }, 500);
    }

    load(model: any): void {
        var m = this._fileUploadService.getModelAndFile(model, this._importResource);
        this._apiService.notifySuccess = false;

        this._apiService.post<any>(Api.Import, m, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.IMPORT_RELOAD_SINGLEITEM, resp);            
        });
    }
    
    initComponent(model: any): void {
        throw new Error("Method not implemented.");
    }
    private _fileUploadService: IFileUploadService;
    private _utilityService: IUtilityService;
    private _importResource: string = "IMPORT_RESOURCE";

    setFileForImport(model: any): void {
        this._fileUploadService.setFile(this._importResource, model);        
    }

    submitForm() {
        throw new Error("Method not implemented.");
    }
    RenderApi: string;
   
    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.FILEUPLOAD_SERVICE) fileUploadService: IFileUploadService
        , @inject(SERVICE_IDENTIFIER.UTILITY_SERVICE) utilityService: IUtilityService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
        this._fileUploadService = fileUploadService;
        this._utilityService = utilityService;
    }

    getDataConsegna(): void {
        var model = this.getModel(UICONSTANTS.DATA_FORM_ACCOUNTING);        
        location.href = Api.ExportDataConsegna + this.buildQueryString(model.DataConsegna_DateFrom, model.DataConsegna_DateTo);
    }

    getDataFattura(): void {
        var model = this.getModel(UICONSTANTS.DATA_FORM_ACCOUNTING);        
        location.href = Api.ExportDataFattura + this.buildQueryString(model.DataFattura_DateFrom, model.DataFattura_DateTo);
    }

    getFatture(): void {
        var model = this.getModel(UICONSTANTS.DATA_FORM_ACCOUNTING);        
        location.href = Api.ExportFatture + this.buildQueryString(model.Fatture_DateFrom, model.Fatture_DateTo);
    }

    private buildQueryString(dtFrom: string, dtTo: string): string {
        if (this.IsNullOrUndefined(dtFrom) || this.IsNullOrUndefined(dtTo)) {
            throw "Date non Valide"; return;
        }
        return '?dateFrom=' + this._sharedService.UTCStringDate(dtFrom) + '&dateTo=' + this._sharedService.UTCStringDate(dtTo);
    }
   
    private IsNullOrUndefined(value: string): boolean {
        return value == null || value == "" || value == undefined;
    }
}