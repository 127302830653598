export const DELEGATES = {
    PROFILE_ENABLEAVATAR: "PROFILE_ENABLEAVATAR",
    PROFILE_UPDATEAVATAR: "PROFILE_UPDATEAVATAR",
    LAYOUT_ALERTHIDE: "LAYOUT_ALERTHIDE",
    LAYOUT_ATTACHEVENT_CLICK: "LAYOUT_ATTACHEVENT_CLICK",
    DOCUMENT_CATALOGUE_LOAD_DATA: "DOCUMENT_CATALOGUE_LOAD_DATA",
    DOCUMENT_LOAD_DATA: "DOCUMENT_LOAD_DATA",
    DOCUMENT_LOAD_TAGS: "DOCUMENT_LOAD_TAGS",
    RECORD_LOAD_DATA: "RECORD_LOAD_DATA",
    TOASTR_SUCCESS: "TOASTR_SUCCESS",
    TOASTR_ERROR: "TOASTR_ERROR",
    POST_ETL_DELEGATE: "POST_ETL_DELEGATE",
    GET_CONTACT_FORM: "GET_CONTACT_FORM",
    TOASTR_INFO: "TOASTR_INFO",
    STAMPINGSOFDAY_LOAD_DATA: "STAMPINGSOFDAY_LOAD_DATA",
    TIMETOWORK_LOAD_DATA: "TIMETOWORK_LOAD_DATA",
    PRESENCES_LOAD_DATA: "PRESENCES_LOAD_DATA",
    PRESENCES_LOAD_STAMPAREA: "PRESENCES_LOAD_STAMPAREA",
    ACTIVITY_LOAD_GRID: "ACTIVITY_LOAD_GRID",
    ACTIVITY_LOAD_ALERT_CASCADE: "ACTIVITY_LOAD_ALERT_CASCADE",
    CALENDAR_LOAD_DATA: "CALENDAR_LOAD_DATA",
    CALENDAR_LOAD_MODAL: "CALENDAR_LOAD_MODAL",
    ACTIVITIES_APPROVAL_LOADDATA: "ACTIVITIES_APPROVAL_LOADDATA",
    ACTIVITIES_APPROVAL_RELOADDATA: "ACTIVITIES_APPROVAL_RELOADDATA",
    ACTIVITIES_APPROVAL_LOAD_EDITNOTE: "ACTIVITIES_APPROVAL_LOAD_EDITNOTE",
    RECORDS_SELF_REGISTRATION_SUBMIT_SUCCESS: "RECORDS_SELF_REGISTRATION_SUBMIT_SUCCESS",
    RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_SUCCESS: "RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_SUCCESS",
    RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_ERROR: "RECORDS_SELF_REGISTRATION_WIZARD_SUBMIT_ERROR",
    RECORDS_SELF_REGISTRATION_ALERT_ERROR: "RECORDS_SELF_REGISTRATION_ALERT_ERROR",
    WIZARD_ERRORS: "WIZARD_ERRORS",
    RELOAD_TAB: "RELOAD_TAB",
    RECORDS_ANA_HR_SUBMIT_SUCCESS: "RECORDS_ANA_HR_SUBMIT_SUCCESS",
    RECORDS_CONTACT_LOADFORM_DATA: "RECORDS_CONTACT_LOADFORM_DATA",
    RECORDS_QUICKREF_LOADJUDMENTS: "RECORDS_QUICKREF_LOADJUDMENTS",
    RECORDS_QUICKREF_REFRESH_QUICKREF_HEADER: "RECORDS_QUICKREF_REFRESH_QUICKREF_HEADER",
    RECORDS_QUICKREF_HANDLEJUDMENTS: "RECORDS_QUICKREF_HANDLEJUDMENTS",
    RECORDS_ANA_PRICING_SUBMIT_SUCCESS: "RECORDS_ANA_PRICING_SUBMIT_SUCCESS",
    RECORDS_ANA_CV_SUBMIT_SUCCESS: "RECORDS_ANA_CV_SUBMIT_SUCCESS",
    RECORDS_ANA_INFOOPERATIVE_SUBMIT_SUCCESS: "RECORDS_ANA_INFOOPERATIVE_SUBMIT_SUCCESS",
    RECORDS_SEARCH_DELEGATE: "RECORDS_SEARCH_DELEGATE",
    TRIGGER_GEOLOC: "TRIGGER_GEOLOC",
    RECORDS_DRAWER_OPEN: "RECORDS_DRAWER_OPEN",
    RECORDS_REDIRECT_TO_DITTAINDIVIDUALE: "RECORDS_REDIRECT_TO_DITTAINDIVIDUALE",
    GET_EDIT_LOCALIZATION_FORM: "GET_EDIT_LOCALIZATION_FORM",
    LOCALIZATION_LOAD_GRID: "LOCALIZATION_LOAD_GRID",
    LOAD_SCORECARD_MODAL: "LOAD_SCORECARD_MODAL",
    LOAD_SCORE_MODAL: "LOAD_SCORE_MODAL",
    SCORECARD_LOAD_GRID: "SCORECARD_LOAD_GRID",
    RESOURCEMATCHING_LOAD_GRID: "RESOURCEMATCHING_LOAD_GRID",
    RESOURCEMATCHING_RELOAD_GRID: "RESOURCEMATCHING_RELOAD_GRID",
    RESOURCEMATCHING_FILL_COMBO: "RESOURCEMATCHING_FILL_COMBO",
    REQUESTSMARTOFFICE_BOOK: "REQUESTSMARTOFFICE_BOOK",
    REQUESTSMARTOFFICE_UNBOOK: "REQUESTSMARTOFFICE_UNBOOK",
    REQUESTSMARTOFFICE_SEARCH: "REQUESTSMARTOFFICE_SEARCH",
    REQUESTSMARTOFFICE_GETDETAIL: "REQUESTSMARTOFFICE_GETDETAIL",
    FEEDBACK_LOAD_GRID: "FEEDBACK_LOAD_GRID",
    IMPORT_RELOAD_SINGLEITEM: "IMPORT_RELOAD_SINGLEITEM",
    FEEDBACK_ADD_NEW_ACTION: "FEEDBACK_ADD_NEW_ACTION",
    FEEDBACK_SAVE: "FEEDBACK_SAVE",
    FEEDBACK_RELOAD_ACTION_AREA: "FEEDBACK_RELOAD_ACTION_AREA",
    FEEDBACK_REMOVE_ACTION: "FEEDBACK_REMOVE_ACTION",
    FEEDBACK_SEARCH: "FEEDBACK_SEARCH",
    FEEDBACK_TASK_SEARCH: "FEEDBACK_TASK_SEARCH",
    FEEDBACK_TASK_LOAD_GRID: "FEEDBACK_TASK_LOAD_GRID",
    FEEDBACK_TASK_LOAD_SUMMARY: "FEEDBACK_TASK_LOAD_SUMMARY",
    FEEDBACK_TASK_FILTER_BY_SUMMARY: "FEEDBACK_TASK_FILTER_BY_SUMMARY",
    NOTIFICATIONS_RELOAD: "NOTIFICATIONS_RELOAD",
    LOAD_TRADUTTOREINFO_MODAL: "LOAD_TRADUTTOREINFO_MODAL",
    FEEDBACK_LOAD_BY_ORDER: "FEEDBACK_LOAD_BY_ORDER",
    LOAD_FEEDBACK_MODAL: "LOAD_FEEDBACK_MODAL",
    NOTIFICATIONS_OPEN_URL: "NOTIFICATIONS_OPEN_URL"
}

