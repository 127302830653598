import { injectable } from "inversify";
import { IPwdStrengthModel, PwdStrengthModel, IPasswordModel } from "../Models/PasswordModel";
import * as pwdStrength from 'zxcvbn';
import { UICONSTANTS } from "../Constants/UIConstants";

export interface IPasswordService {
    init(model: IPasswordModel, strengthMessages?:string[]): void;
    checkPassword(): void;
    togglePassword(passwordVisibleBtn: JQuery): void;
}

@injectable()
export class PasswordService implements IPasswordService {
    togglePassword(passwordVisibleBtn: JQuery): void {
        let input:JQuery = passwordVisibleBtn.parents(".4eh-password-tool").find("input");
        var type = input.attr("type");
        if (type == "text") {
            input.attr("type", "password");
            passwordVisibleBtn.children("i")
                .removeClass(this._passwordModel.IconPasswordInvisible).addClass(this._passwordModel.IconPasswordVisible);
        }
        else {
            input.attr("type", "text");
            passwordVisibleBtn.children("i")
                .removeClass(this._passwordModel.IconPasswordVisible).addClass(this._passwordModel.IconPasswordInvisible);
        }
    } 

    private changeProgressBar(classToRemove: string, classToAdd: string, percentual: number, text: string) {
        $("#" + this._passwordModel.IdStrengthProgressBar)
            .removeClass(classToRemove).addClass(classToAdd)
            .attr("style", "width:" + percentual.toString() + "%");
        $("#" + this._passwordModel.IdStrengthText).empty().html(text);
    }
      
    checkPassword(): void {
        //AA perch� non usi direttamente il modello della view ma passi per il campo di testo?
        //ricordati che ogni view deve memorizzare il modello che viene persistito nella property _model del BaseComponentService

        // LT: PasswordService non � un servizio che si deve legare in modo stretto a ProfileService in quanto potrei aver bisogno di questo 
        // stesso tool anche in un altro posto magari che non utilizza lo stesso servizio o lo stesso modello.
        // Quello che avevo pensato era una sorta di plugin.
        // NOTE: 1. con React, inoltre, elimineremo tutto questo servizio andando ad installare un componente gi� esistente.
        //          Per questo servizio specifico, quindi non mi farei troppi problemi su "utilizzo oggetti Jquery"
        //       2. Non utilizzo l'oggetto model anche perch� ho bisogno dell'oggetto sempre "fresco" in quanto recupero il valore ad ogni keyup. 
        //          Anche questo una volta che utilizzeremo React e il bind non sar� pi� un problema
        let percentual: number = 0;
        let text: string = "";
        let passwordValue = $("#" + this._passwordModel.IdInputBox).val();
        if (passwordValue == "" || passwordValue == null) {
            this.changeProgressBar(UICONSTANTS.CLASS_PROGRESS_WARNING, UICONSTANTS.CLASS_PROGRESS_DANGER, percentual, text);
            return;
        }
        let res = pwdStrength(passwordValue);
        let scoreValue = this._strengths[res.score].Value;
        percentual = (100 * scoreValue) / this._strengths.length;
        text = this._strengths[res.score].Text;
        if (this._strengths[res.score].Value < 2) {
            this.changeProgressBar(UICONSTANTS.CLASS_PROGRESS_WARNING, UICONSTANTS.CLASS_PROGRESS_DANGER, percentual, text);
        }
        if (this._strengths[res.score].Value > 2 && this._strengths[res.score].Value < 4) {
            this.changeProgressBar(UICONSTANTS.CLASS_PROGRESS_DANGER, UICONSTANTS.CLASS_PROGRESS_WARNING, percentual, text);
        }
        if (this._strengths[res.score].Value > 3) {
            this.changeProgressBar(UICONSTANTS.CLASS_PROGRESS_WARNING, UICONSTANTS.CLASS_PROGRESS_SUCCESS, percentual, text);
        }
        
    }
    _strengths: IPwdStrengthModel[] = [
        new PwdStrengthModel("Pessima", 1),
        new PwdStrengthModel("Cattiva", 2),
        new PwdStrengthModel("Discreta", 3),
        new PwdStrengthModel("Buona", 4),
        new PwdStrengthModel("Forte", 5),
    ];
    _passwordValue:string
    _passwordModel: IPasswordModel;
    init(model: IPasswordModel, strengthMessages?: string[]): void {
        this._passwordModel = model;
        if (strengthMessages != null) {
            let length: number = Math.min(strengthMessages.length, this._strengths.length);
            for (var i = 0; i < length; i++) {
                this._strengths[i].Text = strengthMessages[i];
            }
        }
    }
}