import { App } from './app'
import SERVICE_IDENTIFIER from './Wires/Identifiers' //chiavi per la risoluzione dei servizi
import { DELEGATES } from "./Constants/DelegateConstants";
import * as Api from "./Constants/ApiUrlConstants";
import { UICONSTANTS } from './Constants/UIConstants';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

export {
    UICONSTANTS,
    SERVICE_IDENTIFIER,
    DELEGATES,
    App,
    Api
}