import Cookies from 'universal-cookie';
import { injectable } from 'inversify';
import { ILoginModel } from '../Models/LoginModel';

const cookies = new Cookies();

export interface IAuthService {
    isAuthenticated(): boolean;
    getUser(): string;
    getToken(): string;
    signInAndReload(model: ILoginModel): void;
    signIn(model: ILoginModel, postLogin: () => void): void;
    signOut(postLogout: () => void): void;
    signOutAndReload(): void;
    restart(): void;
}

@injectable()
export class AuthService implements IAuthService {
    isAuthenticated(): boolean {
        return cookies.get('auth') != null;
    }

    getUser(): string {
        return cookies.get('user');
    }

    getToken(): string {
        return cookies.get('auth');
    }

    signInAndReload(model: ILoginModel): void {
        this.signIn(model, () => { this.restart(); });
    }
    signIn(model: ILoginModel, postLogin: () => void): void {
        
        //cookies.set('user', model.UserName);
        cookies.set('auth', model.Token, { path: '/' });

        postLogin();
    }

    signOut(postLogout: () => void): void {
        cookies.remove('auth');
        if (postLogout != null) postLogout();
    }
    signOutAndReload(): void {
        this.signOut(() => { this.restart(); });
    }
    restart(): void {
        window.location.href = '/';
    }
}