import { injectable } from "inversify";
import { UICONSTANTS } from "../Constants/UIConstants";

/*
 * Questo Service serve per tutti quei metodi che hanno bisogno di jquery fino a quando non installeremo React.
 * In questo modo andremo ad eliminare solo questo servizio 
 */

export interface IJqueryService {
    managerAlertCustom(): void;
    managerErrorAlert(content: string, title?: string): void;
    managerSuccessAlert(): void;
    attachSaveForm(callback: any): void;
    attachConfirmForm(callback: any, idForm:string): void;
    fillButtonAgregatorArea(html: string): void;
    showLoading(): void;
    hideLoading(): void;
    forceHideModalbackdrop(): void;
}

@injectable()
export class JQueryService implements IJqueryService {
    // viene utilizzato nei casi in cui non si riesta a togliere l'opacit� della modale
    forceHideModalbackdrop(): void {
        $("." + UICONSTANTS.MODAL_BACKDROP).remove();
    }
    showLoading(): void {
        $("#" + UICONSTANTS.ID_PAGE_WRAPPER).addClass(UICONSTANTS.CLASS_SK_LOADING);
        $("." + UICONSTANTS.CLASS_SK_SPINNER).removeClass(UICONSTANTS.CLASS_HIDDEN);
    }
    hideLoading(): void {
        $("#" + UICONSTANTS.ID_PAGE_WRAPPER).removeClass(UICONSTANTS.CLASS_SK_LOADING);
        $("." + UICONSTANTS.CLASS_SK_SPINNER).addClass(UICONSTANTS.CLASS_HIDDEN);
    }
    fillButtonAgregatorArea(html: string): void {
        let area: JQuery = $(UICONSTANTS.BUTTONAGGREGATOR_AREA);

        area.html(html);
    }

    attachSaveForm(callback: any): void {
        let button: JQuery = $(UICONSTANTS.DATA_FORM_SAVE);

        button.click(e => {
            callback();
        });
    }

    // attach la action che deve fare la modale invocata
    attachConfirmForm(callback: any, idForm: string): void {
        let button: JQuery = $(idForm);
        this.attachBtn(button, callback);
    }

    private attachBtn(button: JQuery, callback: any): void {
        button.unbind("click");
        button.click(e => {
            callback();
        });
    }

    // visualizza il messaggio di success (non viene coposto perch� ha un messaggio di default)
    managerSuccessAlert(): void {
        if (!$("#" + UICONSTANTS.ID_ALERT_ERROR).hasClass("hidden")) $("#" + UICONSTANTS.ID_ALERT_ERROR).addClass("hidden");
        $("#" + UICONSTANTS.ID_ALERT_SUCCESS).removeClass("hidden");
    }

    // Di default quando viene cliccato la "x" del dismiss di un alert questo viene distrutto.
    // Questa gestione non ci andava bene quindi � stato creato un comportamento custom, 
    // ovvero al click della "x" viene semplicemente nascosto l'alert
    managerAlertCustom(): void {
        $('.alert .close').on('click', function (e) {
            if ($(this).parent().attr("id") == UICONSTANTS.ID_ALERT_ERROR)
                $(this).parent().children("." + UICONSTANTS.CLASS_ALERT_CONTENT).empty();
            $(this).parent().addClass("hidden");
        });
    }

    // compone e visualizza il messaggio di errore.
    managerErrorAlert(content: string, title?: string): void {
        if (!$("#" + UICONSTANTS.ID_ALERT_SUCCESS).hasClass("hidden")) $("#" + UICONSTANTS.ID_ALERT_SUCCESS).addClass("hidden");

        if (title != null && title != "") content += title + "<br/>" + content;
        
        $("#" + UICONSTANTS.ID_ALERT_ERROR + " ." + UICONSTANTS.CLASS_ALERT_CONTENT).html(content);
        $("#" + UICONSTANTS.ID_ALERT_ERROR).removeClass("hidden");
    }
}