import { injectable, inject, tagged } from "inversify";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import container from "../Wires/Bootstrapper";
import { ILoginModel } from "../Models/LoginModel";
import * as Api from "../Constants/ApiUrlConstants";
import { IApiService, ApiService } from "./ApiService";
import { IErrorHandlerService } from "./ErrorHandlerService";
import { IJqueryService } from "./JQueryService";
import { UICONSTANTS } from "../Constants/UIConstants";
import { DELEGATES } from "../entry";
import { ISharedService } from "./SharedService";
import { IButtonAggregatorService } from "./ButtonAggregator/ButtonAggregatorService";

export interface IConfirmModalService {
    initModalConfirm(modalCode:string, urlToAction: string): void;
    setItemId(id: string, action: (r: any) => void): void;
    setItemModel(model: any, action: (r: any) => void ): void;

    setItemConfirmId(id: string, action: (r: any) => void ): void;
    setItemConfirmModel(model: any, action: (r: any) => void): void;
    //imposta il comportamento a seguito del confirm sulla modala
    setActionPostConfirm(action: () => void);
    getStoredModel(): any;
}
@injectable()
export class ConfirmModalService implements IConfirmModalService {
    setActionPostConfirm(action: () => void) {
        this.actionPostConfirm = action;
    }
    private actionPostConfirm: () => void = () => location.reload();

    setItemId(id: string, action: (r: any) => void = null): void {
        this.setItem(id, UICONSTANTS.DATA_FORM_DELETE, action);
        //$(UICONSTANTS.DATA_ITEM_MODAL_ID).val(id);
        //this._jqueryService.attachConfirmForm(e => { this.callWithSingleKey(); }, UICONSTANTS.DATA_FORM_DELETE);
    }

    setItemModel(model: any, action: (r: any) => void = null): void {
        this.setItem(model, UICONSTANTS.DATA_FORM_DELETE, action);
        //$(UICONSTANTS.DATA_ITEM_MODAL_ID).val(JSON.stringify(model));      
        //this._jqueryService.attachConfirmForm(e => { this.callWithModal(); }, UICONSTANTS.DATA_FORM_DELETE);
    }

    setItemConfirmId(id: string, action: (r: any) => void = null): void {
        this.setItem(id, UICONSTANTS.DATA_FORM_CONFIRM, action);
        //$(UICONSTANTS.DATA_ITEM_MODAL_ID).val(id);
        //this._jqueryService.attachConfirmForm(e => { this.callWithSingleKey(); }, UICONSTANTS.DATA_FORM_CONFIRM);
    }

    setItemConfirmModel(model: any, action: (r: any) => void=null): void {
        this.setItem(model, UICONSTANTS.DATA_FORM_CONFIRM, action);
        //$(UICONSTANTS.DATA_ITEM_MODAL_ID).val(JSON.stringify(model));
        //this._jqueryService.attachConfirmForm(e => { this.callWithModal(); }, UICONSTANTS.DATA_FORM_CONFIRM);
    }

    private setItem(model: any, idForm: string, action: (r: any) => void): void {
        if (typeof model === "string") {
            $(UICONSTANTS.DATA_ITEM_MODAL_ID).val(model);
            if (action != null)
                this._jqueryService.attachConfirmForm(e => { action(model); }, idForm);
            else
                this._jqueryService.attachConfirmForm(e => { this.callWithSingleKey(); }, idForm);
            return;
        }
        $(UICONSTANTS.DATA_ITEM_MODAL_ID).val(JSON.stringify(model));
        
        if (action != null)
            this._jqueryService.attachConfirmForm(e => { action(model); }, idForm);   
        else
            this._jqueryService.attachConfirmForm(e => { this.callWithModal(); }, idForm);   
    }
    //delegates: { [id: string]: any; } = {};
    private urlsToAction: { [id: string]: string; } = {};
    // usato in app.ts 
    // serve per inizializzare il servizio già con l'url da chiamare successivamente
    initModalConfirm(modalId: string, urlToAction: string): void {
        this.urlsToAction[modalId] = urlToAction;
    }

    private getUrlToAction(): string {
        let modalId = "";
        // caso in qui abbia la modale aperta sopra un'altra modale
        $.each($(".modal.in"), (i, val) => {
            if (this.urlsToAction.hasOwnProperty($(val).attr("id")))
                modalId = $(val).attr("id");
        });
        if (modalId == "")
            return "";

        return this.urlsToAction[modalId];
    }
        
    // chiamata in get con il passaggio solamente dell'ID (tendenzialmente usata per la delete)
    protected callWithSingleKey() {        
        let id = $(UICONSTANTS.DATA_ITEM_MODAL_ID).val();
        let url = this.getUrlToAction();
        var parms = id.indexOf("?") < 0 ? "?id=" + id : id;
        this._apiService.get<any>(url + parms, null, (resp) => {
            this.actionPostConfirm();
        });
    }

    // chiamata in get con il passaggio del model (tendenzialmente usata per aggiornamenti)
    protected callWithModal() {        
        let model = JSON.parse($(UICONSTANTS.DATA_ITEM_MODAL_ID).val());
        let url = this.getUrlToAction();
        this._apiService.post<any>(url, model, (resp) => {
            this.actionPostConfirm();
        });
    }

    public getStoredModel(): any {
        let storedItem = $(UICONSTANTS.DATA_ITEM_MODAL_ID).val();
        if (storedItem == null || storedItem == "") return null;
        return JSON.parse(storedItem);
    }

    protected _apiService: IApiService;
    protected _handlerService: IErrorHandlerService;
    //protected _sharedService: ISharedService;
    protected _jqueryService: IJqueryService;

    constructor(@inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService,
        @inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService,
        //@inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService,
        @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService) {
        this._apiService = apiService;
        this._handlerService = handlerService;
        //this._sharedService = sharedService;
        this._jqueryService = jqueryService;
        //this._sharedService.setRelatedService(this);
    }


}