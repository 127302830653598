export interface ILoginModel {
    UserName: string;
    PassWord: string;
    Token: string;
    Role: string;
    UserId: string;
}

export class LoginModel implements ILoginModel {
    UserName: string;
    PassWord: string;
    Token: string;
    Role: string;
    UserId: string;
}

export interface ITokenModel {
    UserId: string;
    Route: string;
    Parameters: string;   
    JwtToken: string;
    IsValid: boolean;
}

