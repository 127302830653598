import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { DELEGATES } from '../entry';

export interface IScorecardService extends IBaseComponentService<any> {
    simpleSearch(searcher: any): void;
    getScorecard(id: string, scorecardType:string): void
    getHistoricalScorecard(id: string, editable?: boolean): void
    deleteScorecard(id: string): void
    addScoreItem(id: string, scorecardType: string): void
    saveScorecard(id: string, complete: boolean): void
    changePartialScore(rowId: string, scoreVal: string): void
    openDetailPop(id: string, editable?: boolean): void
}

@injectable()
export class ScorecardService extends BaseComponentService<any> implements IScorecardService {
    openDetailPop(id: string, editable?: boolean): void {
        
        this._sharedService.registerCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, res => {
            $("div[data-4eh-scorecard-form]").html(res);
            window["OpenModal"]("#scorecard");            
        });
        this.getHistoricalScorecard(id, editable);
    }

    changePartialScore(rowId: string, scoreVal: string): void {
        let color = scoreVal == "1" ? "success" : scoreVal == "3" ? "warning" : "danger";
        $(rowId + " [data-4eh-score]").removeClass().addClass("text-white badge badge-" + color).html(scoreVal);
        this.calcTotal();
    }

    private calcTotal(): void {
        var total = 0;
        $.each($("[data-4eh-score]"), function (i, val) {
            var scoreVal = Number($(this).html());
            total += scoreVal;
        });
        $("[data-4eh-score-total]").html(total.toString());
    }

    addScoreItem(id: string, scorecardType:string) {
        this._apiService.notifySuccess = false;
        let url = Api.AddScoreItem + "?id=" + id + "&type=" + scorecardType;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORE_MODAL, resp);
        });
    }

    getScorecard(id: string, scorecardType :string) {
        this._apiService.notifySuccess = false;
        let url = Api.GetScorecard + "?id=" + id + "&type=" + scorecardType;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, resp);
        });
    }

    getHistoricalScorecard(id: string, editable?: boolean) {
        this._apiService.notifySuccess = false;
        let url = Api.GetHistoricalScorecard + "?id=" + id + "&editable=" + editable;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, resp);
        });
    }

    deleteScorecard(id: string) {
        this._apiService.notifySuccess = false;
        let url = Api.DeleteScoreItem + "?id=" + id;
        this._apiService.get<any>(url, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, resp);
        });
    }

    saveScorecard(id: string, completed: boolean=false) {
        let list = [];
        $.each($(".score-row"), function (i, val) {
            list.push({
                "Id": $(this).find("[name='id']").val(),
                "ErrorTypeId": $(this).find("[name='error-type']").val() == "" ? null : $(this).find("[name='error-type']").val(),
                "SeverityId": $(this).find("[name='severity']").val() == "" ? null : $(this).find("[name='severity']").val(),
                "Note": $(this).find("[name='note']").val(),
                "Score": $(this).find("[data-4eh-score]").text() == "" ? 0 : $(this).find("[data-4eh-score]").text(),
            });
        });
        let model = this.getModel("#scorecard-form");
        model["ScoreItems"] = list;        
        model["Completed"] = completed;
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.SaveScorecard, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.LOAD_SCORECARD_MODAL, resp);
        });
    }

    simpleSearch(searcher: any): void {
        this._apiService.notifySuccess = false;
        this._apiService.post<any>(Api.ScorecardSearch, searcher, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.SCORECARD_LOAD_GRID, resp);
        });
    }

    

    initComponent(model: any): void {
    }
    submitForm() {

    }


    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
    }
}