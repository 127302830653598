import { injectable, inject } from "inversify";
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import { IJqueryService } from "../Shared/JQueryService";
import { ISharedService } from "../Shared/SharedService";
import { IButtonAggregatorService } from "../Shared/ButtonAggregator/ButtonAggregatorService";

export interface ITestPageService extends IBaseComponentService<any> {
    DoSomething(model: any);
}

@injectable()
export class TestPageService extends BaseComponentService<any> implements ITestPageService {
    attachToolBarEvents(): void {
        throw new Error("Method not implemented.");
    }
    submitForm() {
        throw new Error("Method not implemented.");
    }

    DoSomething(model: any) {               
        this._apiService.post<any>(Api.TestMethod, model, (resp) => {

            alert(JSON.stringify(resp));

        }, (err) => {
            this._handlerService.Error(err);
        });
    }
    
    getProviderName(): string {
        return PROVIDERS_REGISTRATION.TEST;
    }
    RenderApi: string = Api.TestRender;    

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService        ,
        @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService  
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);       
    }
}