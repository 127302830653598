import Cookies from 'universal-cookie';
import { injectable, inject } from 'inversify';
import { ILoginModel, LoginModel, ITokenModel } from '../Models/LoginModel';
import { AuthService, IAuthService } from './AuthService';
import { IApiService, ApiService } from './ApiService';
import { IErrorHandlerService } from './ErrorHandlerService';
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import IoCContainer from '../Wires/Bootstrapper';
import { ISignalR } from '../Services/SignalR';

export interface ILoginService extends IAuthService {  
    logIn(model: ILoginModel): void;       
    logOut(): void;  
    changeRole(model: ILoginModel): void;  
    logInSSO(model: ITokenModel): void;
    signingUp(model: any): void;
    impersonate(model: ILoginModel): void;
}

@injectable()
export class LoginService extends AuthService implements ILoginService {
    _handlerService: IErrorHandlerService;
    
    changeRole(model: ILoginModel): void {
        let apiService: IApiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        apiService.notifySuccess = false;
        apiService.showSpinnerLoading = false;
        apiService.get<any>(Api.ChangeRole, { params: { role: model.Role } }, (resp) => {            
            if (resp.isValid == true) {
                this.signOut(null);
                this.signInAndReload(this.setModel(resp.token));
            }
        }
        //    , (err) => {
        //    this._handlerService.Error(err);
        //    }
        );
    }

    signingUp(model: any): void
    {
        let apiService: IApiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        apiService.post("/api/signup/SigningUp", model, (resp) => { });
    }

    logOut(): void {
        let apiService: IApiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        apiService.notifySuccess = false;
        apiService.showSpinnerLoading = false;
        apiService.post<any>(Api.LogOut, null, (resp) => {            
            if (resp.isValid == true) {      
               
                this.signOutAndReload();
            }
        }
        //    , (err) => {
        //    this._handlerService.Error(err);
        //    }
        );
    }    

    logOutImpersonate(): void {
        let apiService: IApiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        apiService.notifySuccess = false;
        apiService.showSpinnerLoading = false;
        apiService.post<any>(Api.LogOutImpersonate, null, (resp) => {
            if (resp.isValid == true) {

                this.signInAndReload(this.setModel(resp.token));
            }
        }
            //    , (err) => {
            //    this._handlerService.Error(err);
            //    }
        );
    }    
   
    logIn(model: ILoginModel): void {
        let apiService: IApiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        apiService.notifySuccess = false;
        apiService.showSpinnerLoading = false;
        apiService.post<any>(Api.Login, model, (resp) => {            
            if (resp.isValid == true) {                       
                this.signInAndReload(this.setModel(resp.token));
            }
        }
        //    , (err) => {
        //    this._handlerService.Error(err);
        //    }
        );
    }

    impersonate(model: ILoginModel): void {
        let apiService: IApiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        apiService.notifySuccess = false;
        apiService.showSpinnerLoading = false;
        apiService.post<any>(Api.Impersonate, model, (resp) => {
            if (resp.isValid == true) {
                this.signInAndReload(this.setModel(resp.token));
            }
        });
    }

    logInSSO(model: ITokenModel): void {        
        if (model.IsValid == true) {
            this.signIn(this.setModel(model.JwtToken), () =>
            {
                if (model.Parameters == null)
                    window.location.href = model.Route;
                else
                    window.location.href = model.Route + "?" + model.Parameters;
            });            
        }
    }

    private setModel(token: string): ILoginModel {
        let result = new LoginModel();
        result.Token = token;
        return result;
    }

    constructor(       
        @inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService) {
        super();//chiama il costruttore di default            
        this._handlerService = handlerService;        
    } 
}