import { injectable, inject } from 'inversify';
import { IApiService } from "../Shared/ApiService";
import { IErrorHandlerService } from "../Shared/ErrorHandlerService";
import * as Api from "../Constants/ApiUrlConstants";
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { ICropperService } from './CropperService';
import { BaseComponentService, IBaseComponentService } from "../Shared/BaseComponentService";
import { ISharedService } from "../Shared/SharedService";
import { DELEGATES } from "../Constants/DelegateConstants";
import { IJqueryService } from '../Shared/JQueryService';
import { IButtonAggregatorService } from '../Shared/ButtonAggregator/ButtonAggregatorService';
import { UICONSTANTS } from '../entry';
import { IFileUploadService } from '../Shared/FileUploadService';


export interface IETLService extends IBaseComponentService<IETLModel> {
    import(uri: string): void;
    setFileForDocument(model: any): void;
    ImportClientiRisorse(): void
}

@injectable()
export class ETLService extends BaseComponentService<IETLModel> implements IETLService {

    private _fileUploadService: IFileUploadService;
    private _etlResource: string = "ETL_RESOURCE";

    setFileForDocument(event: any): void {
        this._fileUploadService.setFile(this._etlResource, event);
    }

    ImportClientiRisorse(): void {
        $("button[data-4eh-id='sync-risorse']").addClass("disabled").prop("disabled", "disabled");
        this._apiService.get<any>(Api.ImportDominioPlunet, null, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 8 });

            this._apiService.get<any>(Api.ImportRisorse, null, (resp) => {
                this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 16 });

                this._apiService.get<any>(Api.ImportRisorseEsterne, null, (resp) => {
                    this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 24 });

                    this._apiService.get<any>(Api.ImportCapacity, null, (resp) => {
                        this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 32 });

                        this._apiService.get<any>(Api.ImportClienti, null, (resp) => {
                            this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 40 });

                            this._apiService.get<any>(Api.ImportOrdini, null, (resp) => {
                                this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 48 });

                                this._apiService.get<any>(Api.ImportOrdiniManuali, null, (resp) => {
                                    this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 56 });

                                    this._apiService.get<any>(Api.ImportOrdiniDaAssegnare, null, (resp) => {
                                        this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 68 });

                                        this._apiService.get<any>(Api.ImportQualityScore, null, (resp) => {
                                            this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 78 });

                                            this._apiService.get<any>(Api.SetDefaultTraduttori, null, (resp) => {
                                                this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 90 });

                                                this._apiService.get<any>(Api.ImportCaricoTraduttori, null, (resp) => {
                                                    this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 100 });

                                                    this._apiService.get<any>(Api.ImportContentTypeGroup, null, (resp) => {
                                                        this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, { percent: 100 });
                                                        $("button[data-4eh-id='sync-risorse']").removeClass("disabled").removeAttr("disabled");
                                                    }, (err) => {
                                                        this.importError(err);
                                                    });

                                                }, (err) => {
                                                    this.importError(err);
                                                });
                                            }, (err) => {
                                                this.importError(err);
                                            });
                                        }, (err) => {
                                            this.importError(err);
                                        });

                                    }, (err) => {
                                        this.importError(err);
                                    });

                                }
                                    , (err) => {
                                        this.importError(err);
                                    });

                            }, (err) => {
                                this.importError(err);
                            });

                        }, (err) => {
                            this.importError(err);
                        });
                    }, (err) => {
                        this.importError(err);
                    });
                }, (err) => {
                    this.importError(err);
                });
            }, (err) => {
                this.importError(err);
            });
        }, (err) => {
            this.importError(err);
        });
    }

    private importError(err): void {
        let stringNotifies: string;
        if (typeof err.data === "string")
            stringNotifies = err.data
        if (typeof err.data === "object") {
            let notifies = err.data.notifies;
            if (notifies == null) {
                notifies = [];
                notifies.push(err.data);
            }
            stringNotifies = this._handlerService.SetNotifies(notifies);
        }

        $("#import-failed-box .card-block").html(stringNotifies);
        $("#import-failed-box").removeClass("hidden");
        $("button[data-4eh-id='sync-risorse']").removeClass("disabled").removeAttr("disabled");
    }

    import(uri: string): void {

        this.getModel("div[data-4eh-form='etl-form']");
        var model = this._fileUploadService.getModelAndFile(this._model, this._etlResource);
        this._apiService.post<any>(uri, model, (resp) => {
            this._sharedService.resolveCrossDelegate(DELEGATES.POST_ETL_DELEGATE, resp);
        }, (err) => {
        });
    }

    submitForm() {
        throw new Error("Method not implemented.");
    }

    RenderApi: string;

    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) handlerService: IErrorHandlerService
        , @inject(SERVICE_IDENTIFIER.API_SERVICE) apiService: IApiService
        , @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService
        , @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService
        , @inject(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR) buttonAggregatorService: IButtonAggregatorService
        , @inject(SERVICE_IDENTIFIER.FILEUPLOAD_SERVICE) fileUploadService: IFileUploadService
    ) {
        super(apiService, handlerService, buttonAggregatorService, sharedService, jqueryService);
        this._fileUploadService = fileUploadService;
    }
}