import "reflect-metadata";
import { Container } from "inversify";
import SERVICE_IDENTIFIER from "./Identifiers";
import { ITestPageService, TestPageService } from "../Services/TestPageService";
import { IErrorHandlerService,ErrorHandlerService } from "../Shared/ErrorHandlerService";
import { IApiService, ApiService} from "../Shared/ApiService";
import { LoginService, ILoginService } from "../Shared/LoginService";
import { ISignalR, SignalR } from "../Services/SignalR";
import { IProfileService, ProfileService } from "../Services/ProfileService";
import { ISharedService, SharedService } from "../Shared/SharedService";
import { ICropperService, CropperService } from "../Services/CropperService";
import { IPasswordService, PasswordService } from "../Services/PasswordService";
import { IJqueryService, JQueryService } from "../Shared/JQueryService";
import { IButtonAggregatorService, ButtonAggregatorService } from "../Shared/ButtonAggregator/ButtonAggregatorService";
import { IControlPanelService, ControlPanelService } from "../Shared/ControlPanelService";
import { IFileUploadService, FileUploadService } from "../Shared/FileUploadService";
import { IConfirmModalService, ConfirmModalService } from "../Shared/ConfirmModalService";
import { AccountService, IAccountService } from "../Services/AccountService";
import { RecordService, IRecordService } from "../Services/RecordService";
import { IDocumentService, DocumentService } from "../Services/DocumentService";
import { IETLService, ETLService } from "../Services/ETLService";
import { IPresenceService, PresenceService } from "../Services/PresenceService";
import { IActivityService, ActivityService } from "../Services/ActivityService";
import { IRequestService, RequestService } from "../Services/RequestService";
import { IUtilityService, UtilityService } from "../Shared/UtilityService";
import { IGeoService, GeoService } from "../Services/GeoService";
import { PowerBiService, IPowerBiService } from "../Shared/PowerBiService";
import { ExportService, IExportService } from "../Services/ExportService";
import { IScorecardService, ScorecardService } from "../Services/ScorecardService";
import { IResourceMatchingService, ResourceMatchingService } from "../Services/ResourceMatchingService";
import { ISvgService, SvgService } from "../Services/SvgService";
import { IRequestSmartOfficeService, RequestSmartOfficeService } from "../Services/RequestSmartOfficeService";
import { IFeedbackService, FeedbackService } from "../Services/FeedbackService";
import { IBlazorService, BlazorService } from "../Shared/BlazorService";



let container = new Container();
container.bind<IButtonAggregatorService>(SERVICE_IDENTIFIER.BUTTON_AGGREGATOR).to(ButtonAggregatorService);//.whenTargetNamed(PROVIDERS_REGISTRATION.TEST);
container.bind<ITestPageService>(SERVICE_IDENTIFIER.TEST_PAGE).to(TestPageService);
container.bind<IErrorHandlerService>(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE).to(ErrorHandlerService);
container.bind<ILoginService>(SERVICE_IDENTIFIER.AUTH_SERVICE).to(LoginService);
container.bind<IApiService>(SERVICE_IDENTIFIER.API_SERVICE).to(ApiService);
container.bind<ISignalR>(SERVICE_IDENTIFIER.SIGNALR).to(SignalR).inSingletonScope();
container.bind<IProfileService>(SERVICE_IDENTIFIER.PROFILE_SERVICE).to(ProfileService);
container.bind<IDocumentService>(SERVICE_IDENTIFIER.DOCUMENT_SERVICE).to(DocumentService);
container.bind<IETLService>(SERVICE_IDENTIFIER.ETL_SERVICE).to(ETLService);
container.bind<IControlPanelService>(SERVICE_IDENTIFIER.CONTROLPANEL_SERVICE).to(ControlPanelService);
container.bind<ISharedService>(SERVICE_IDENTIFIER.SHARED_SERVICE).to(SharedService).inSingletonScope();
container.bind<ICropperService>(SERVICE_IDENTIFIER.CROPPER_SERVICE).to(CropperService).inSingletonScope();
container.bind<IPasswordService>(SERVICE_IDENTIFIER.PASSWORD_SERVICE).to(PasswordService).inSingletonScope();
container.bind<IJqueryService>(SERVICE_IDENTIFIER.JQUERY_SERVICE).to(JQueryService).inSingletonScope();
container.bind<IFileUploadService>(SERVICE_IDENTIFIER.FILEUPLOAD_SERVICE).to(FileUploadService);
container.bind<IAccountService>(SERVICE_IDENTIFIER.ACCOUNT_SERVICE).to(AccountService);
container.bind<IRecordService>(SERVICE_IDENTIFIER.RECORD_SERVICE).to(RecordService);
container.bind<IConfirmModalService>(SERVICE_IDENTIFIER.CONFIRM_MODAL).to(ConfirmModalService).inSingletonScope();
container.bind<IPresenceService>(SERVICE_IDENTIFIER.PRESENCE_SERVICE).to(PresenceService);
container.bind<IActivityService>(SERVICE_IDENTIFIER.ACTIVITY_SERVICE).to(ActivityService);
container.bind<IRequestService>(SERVICE_IDENTIFIER.REQUEST_SERVICE).to(RequestService);
container.bind<IUtilityService>(SERVICE_IDENTIFIER.UTILITY_SERVICE).to(UtilityService);
container.bind<IGeoService>(SERVICE_IDENTIFIER.GEO_SERVICE).to(GeoService);
container.bind<IPowerBiService>(SERVICE_IDENTIFIER.POWERBI_SERVICE).to(PowerBiService);
container.bind<IExportService>(SERVICE_IDENTIFIER.EXPORT_SERVICE).to(ExportService);
container.bind<IScorecardService>(SERVICE_IDENTIFIER.SCORECARD_SERVICE).to(ScorecardService);
container.bind<IResourceMatchingService>(SERVICE_IDENTIFIER.RESOURCEMATCHING_SERVICE).to(ResourceMatchingService);
container.bind<IRequestSmartOfficeService>(SERVICE_IDENTIFIER.REQUESTSMARTOFFICE_SERVICE).to(RequestSmartOfficeService);
container.bind<ISvgService>(SERVICE_IDENTIFIER.SVG_SERVICE).to(SvgService);
container.bind<IFeedbackService>(SERVICE_IDENTIFIER.FEEDBACK_SERVICE).to(FeedbackService);
container.bind<IBlazorService>(SERVICE_IDENTIFIER.BLAZOR_SERVICE).to(BlazorService);

export default container;