import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { injectable, inject } from 'inversify';
import { IErrorHandlerService } from './ErrorHandlerService';
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { IAuthService } from './AuthService';
import { ILoginService } from './LoginService';
import { IJqueryService } from './JQueryService';
import { ISharedService } from './SharedService';
import { DELEGATES } from "../Constants/DelegateConstants";

export interface IApiService {
    get<T>(url: string, params: any, success?: (resp: T) => void, error?: (err: any) => void): void;
    getAsync<T>(url: string, params: any): Promise<T>;
    getString(url: string, params: any, success?: (resp: string) => void, error?: (err: any) => void): void;
    post<T>(url: string, params: any, success?: (resp: T) => void, error?: (err: string) => void, config?: AxiosRequestConfig): void;
    postString(url: string, params: any, success: (resp: string) => void, error: (err: string) => void): void;
    postAsync<T>(url: string, params: any): Promise<T>;
    //AA se c'è un sistema migliore usiamolo
    //se true notifica l'utente del completamente dell'operazione
    notifySuccess: boolean;
    // se true visualizza lo spinner
    showSpinnerLoading: boolean;
}

@injectable()
export class ApiService implements IApiService {
    notifySuccess: boolean;
    showSpinnerLoading: boolean;
    client: AxiosInstance = axios.create();
    _errorHandlerService: IErrorHandlerService;
    _jqueryService: IJqueryService;
    _sharedService: ISharedService;
    authService: ILoginService;
    constructor(@inject(SERVICE_IDENTIFIER.ERROR_HANDLER_SERVICE) ErrorHandlerService: IErrorHandlerService,
        @inject(SERVICE_IDENTIFIER.AUTH_SERVICE) auth: ILoginService,
        @inject(SERVICE_IDENTIFIER.JQUERY_SERVICE) jqueryService: IJqueryService,
        @inject(SERVICE_IDENTIFIER.SHARED_SERVICE) sharedService: ISharedService) {

        this.client.interceptors.response.use(this.handleResponeSucces, this.handleResponeError);
        this.client.interceptors.request.use(this.handleRequest, this.handleRequestError);
        this._errorHandlerService = ErrorHandlerService;
        this._jqueryService = jqueryService;
        this._sharedService = sharedService;
        this.authService = auth;

        this.notifySuccess = true;
        this.showSpinnerLoading = true;
    }

    protected handleResponeSucces = (response) => {
        if (this.showSpinnerLoading)
            this._jqueryService.hideLoading();

        if (response.data.isValid) return response;
        
        if (response.data.notifies != null) {
            //this._errorHandlerService.Error({ response: response });
            return Promise.reject(response);
        }

        return response;
    }

    protected handleResponeError = (err) => {        
        //this._errorHandlerService.Error(err);
        if (this.showSpinnerLoading)
            this._jqueryService.hideLoading();
        if (err.response.status === 403) // La richiesta è legittima ma il server si rifiuta di soddisfarla        
        {
            this._errorHandlerService.Error(err);
            return Promise.reject(err);
        }

        if (err.response.status === 401 ||
            err.response.status === 406)
            /*||err.response.status === 403)*/ {
            //this._errorHandlerService.Error(err);
            this.authService.logOut();
            return Promise.reject(err);
        }


        //this._errorHandlerAction.Error({ isError: true, message: err.response });
        //this._store.dispatch(ErrorShow({ isError:true, message : err.response}));
        // { type: 'ERROR_SHOW', payload : { isError:true, message : err.response.data.Message} });

        return Promise.reject(err);;
    };

    protected handleRequest = (config) => {        
        const token = this.authService.getToken();
        
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;           
        }
        if (this.showSpinnerLoading)
            this._jqueryService.showLoading();
        //this._apiLoaderAction.SetIsLoading();
        config.url = this.handleUrl(config.url);
        return config;
    }

    protected handleRequestError = error => {
        if (this.showSpinnerLoading)
            this._jqueryService.hideLoading();
        // Do something with request error       
        Promise.reject(error);
    }

    protected isTemplatedUrl(url: string): boolean {
        return url.indexOf("{controller}") != -1;
    }
    protected handleUrl(url: string) {        
        if (!this.isTemplatedUrl(url)) return url;
            
        let contextData = this._sharedService.getContextData();
        if (contextData == null) return url;
        
        let controller = contextData.Controller;
        if (controller == null) return url;

        return url.replace("{controller}", controller);        
    }

    public get<T>(url: string, params: any, success?: (resp: T) => void, error?: (err: any) => void) {
        this.client.get(url, params).then(resp => {
            //let data = SerializationHelper.toInstance<T>(resp.data)
            this.notifySuccess = false;
            this.handleSuccess(resp, success);
        }).catch(err => {
            this.handleError(err, error);
        });
    }

    public async getAsync<T>(url: string, params: any): Promise<T> {
        return await this.client.get(url, { params: { ...params } }).then((resp) => this.handleSuccess(resp, null)).catch((err) => this.handleError(err, null));
        //let data = SerializationHelper.toInstance<T>(resp.data,type)
    }

    public getString(url: string, params: any, success?: (resp: string) => void, error?: (err: any) => void) {
        this.client.get(url, { params: { ...params } }).then(resp => this.handleSuccess(resp, success)).catch(err => {
            this.handleError(err, error);
        });
    }

    public post<T>(url: string, params: any, success?: (resp: T) => void, error?: (err: string) => void, config?: AxiosRequestConfig) {
        this.client.post(url, params, config)
            .then(resp => {
                // let data = SerializationHelper.toInstance<T>(resp.data)
                this.handleSuccess(resp, success);

            }).catch(err => {
                this.handleError(err, error);

            });
    }
    public async postAsync<T>(url: string, params: any): Promise<T> {
        return await this.client.post(url, params).then((resp) => this.handleSuccess(resp, null)).catch((err) => this.handleError(err, null));
    }


    public postString(url: string, params: any, success: (resp: string) => void, error: (err: string) => void) {
        this.client.post(url, params).then(resp => this.handleSuccess(resp, success)).catch(err => {
            this.handleError(err, error);
        });
    }

    protected handleError(err, callback) {

        if (callback != null)
            callback(err);
        else
            this._errorHandlerService.Error(err);
    }

    protected handleSuccess(resp, success): any {        
        //debugger;
        let ret = resp;
        if (resp == null) ret = { data: { isValid: false } };
        if (this.notifySuccess) this._sharedService.resolveCrossDelegate(DELEGATES.TOASTR_SUCCESS); //this._jqueryService.managerSuccessAlert();
        if (success != null) {
            success(ret.data);
            return;
        }
        return ret.data;
    }



}

